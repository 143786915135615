import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import DashboardMenu from "./menu";
import Projects from "./projects";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/loading";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import ReaderHeader from "../Readerscreen/readerHeader";

const Dashboard = () => {
  const { user } = useAuth0();
  const { isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const isUserAuthenticated = useUserAuthentication();

  const [query, setQuery] = useState("");

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <Flex>
      <DashboardMenu />
      <Flex
        className="dashboard__body"
        width="100%"
        height="100vh"
        direction="column"
        backgroundColor="#fff"
      >
        <ReaderHeader setQuery={setQuery} />
        <Flex height="100%" w="100%" direction="row">
          <Flex direction="column">
            <Projects query={query} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
