/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import "react-circular-progressbar/dist/styles.css";
import { useGetUserProjectsQuery } from "../../state/api/medicalApi";
import "../../styles/dashboard.css";
import Loading from "../Loading/loading";
import DisplayCases from "./displaycases";

const ReaderProjects = ({ query }) => {
  const { user } = useAuth0();
  const { data: projects, isLoading } = useGetUserProjectsQuery({
    subClaim: user?.sub,
  });

  return (
    <Flex
      backgroundColor="#ECECEC"
      pos="absolute"
      boxShadow="0px 4px 4px rgba(21, 28, 37, 0.05)"
      overflow="auto"
      width="81.4583vw"
      height="64.074vh"
      left="17.604vw"
      top="31.6666vh"
      direction="column"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box width="100%" height="5.3703vh" background="#E4E5E8">
            <Text
              fontFamily="fira sans"
              fontSize="1.25vw"
              fontWeight={600}
              ml="1.25vw"
              mt="1.3888vh"
              mb="1.3888vh"
            >
              Clinical study
            </Text>
          </Box>

          {projects?.projects.length > 0 ? (
            projects?.projects[0].type === "multiSlide" ? (
              <Box padding="0.9375vw" overflowX="auto">
                <VStack bgColor="#ECECEC">
                  <HStack
                    color="#151C25"
                    width="100%"
                    mb="10px"
                    fontWeight={600}
                  >
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      textAlign="center"
                      minW="8%"
                    >
                      Sr.no
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="18%"
                    >
                      Cases
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="12%"
                    >
                      Slide Name 1
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="10%"
                    >
                      Stain Type 1
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="12%"
                    >
                      Slide Name 2
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="10%"
                    >
                      Stain Type 2
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="14%"
                    >
                      Date
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="13%"
                    >
                      Progress
                    </Text>
                  </HStack>
                  {projects?.projects.map((project) => {
                    return (
                      <DisplayCases
                        key={project?._id}
                        uploadedAt={project?.createdAt}
                        projectName={project?.name}
                        tasks={project?.cases}
                        projectType={project?.type}
                        slideType={project?.slideType}
                        projectInfo={projects?.userProjectsProgress?.find(
                          (proj) => proj.project === project._id
                        )}
                        projectId={project?._id}
                        questionnaire={project?.questionnaire}
                        input={query}
                      />
                    );
                  })}
                </VStack>
              </Box>
            ) : (
              <Box padding="0.9375vw" overflowX="auto">
                <VStack bgColor="#ECECEC">
                  <HStack
                    color="#151C25"
                    width="100%"
                    mb="10px"
                    fontWeight={600}
                  >
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      textAlign="center"
                      minW="8%"
                    >
                      Sr.no
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="30%"
                    >
                      Slide Name
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="25%"
                    >
                      Stain Type
                    </Text>

                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="20%"
                    >
                      Date
                    </Text>
                    <Text
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      minW="13%"
                    >
                      Progress
                    </Text>
                  </HStack>
                  {projects?.projects.map((project) => {
                    return (
                      <DisplayCases
                        key={project?._id}
                        uploadedAt={project?.createdAt}
                        projectName={project?.name}
                        tasks={project?.cases}
                        projectType={project?.type}
                        slideType={project?.slideType}
                        projectInfo={projects?.userProjectsProgress?.find(
                          (proj) => proj.project === project._id
                        )}
                        projectId={project?._id}
                        questionnaire={project?.questionnaire}
                        input={query}
                      />
                    );
                  })}
                </VStack>
              </Box>
            )
          ) : (
            "No assigned Projects"
          )}
        </>
      )}
      <Box background="#ECECEC" width="100%" height="7vh"></Box>
    </Flex>
  );
};

export default ReaderProjects;
