import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import ReaderMenu from "./readerMenu";
import ReaderProjects from "./readerprojects";
import { useGetUserProjectsQuery } from "../../state/api/medicalApi";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/loading";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import ProjectInviteReader from "./projectinvitereader";
import ReaderOverallStats from "../StatsView/readerOverallStats";
import ReaderHeader from "./readerHeader";
import ClinicalStudy from "./clinicalStudy";

const Dashboard = () => {
  const { user } = useAuth0();

  const isUserAuthenticated = useUserAuthentication();

  const { data: projects, isLoading } = useGetUserProjectsQuery({
    subClaim: user?.sub,
  });

  const [casesTotal, setTotalCases] = useState(0);
  const [caseCompleted, setCaseCompleted] = useState(0);

  useEffect(() => {
    if (!projects) return;
    let totalCases = 0;
    projects.projects.map((cases) => {
      totalCases += cases.cases.length;
    });

    let completeCases = 0;
    projects.userProjectsProgress.forEach((project) => {
      project.casesProgress.forEach((c) => {
        if (c.caseStatus === "completed") completeCases++;
      });
    });

    setTotalCases(totalCases);
    setCaseCompleted(completeCases);
  });

  const [query, setQuery] = useState("");

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <Flex>
      <ReaderMenu />
      <Flex
        className="dashboard__body"
        w="100%"
        height="100vh"
        direction="column"
        backgroundColor="#fff"
      >
        <ReaderHeader setQuery={setQuery} />
        <Flex
          height="100%"
          w="100%"
          direction="row"
          marginTop="4.259vh"
          ml="1.718vw"
        >
          <Flex direction="column">
            <ClinicalStudy />
          </Flex>
          <Flex direction="column" marginRight="20px">
            <ReaderOverallStats total={casesTotal} completed={caseCompleted} />
            <ReaderProjects query={query} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
