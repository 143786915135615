import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Flex,
  Box,
  Text,
  HStack,
  Divider,
} from "@chakra-ui/react";
import _ from "lodash";

const StatsTable = ({ casesStats, total, completed }) => {
  // const TD = ({ children }) => (
  //   <Td
  //     border="1px solid rgba(0, 0, 0, 0.1)"
  //     whiteSpace="nowrap"
  //     fontFamily="Inter"
  //     fontWeight="semibold"
  //     p={isScreenLessThan1536px ? 1 : 3}
  //     fontSize={isScreenLessThan1536px ? "12px" : "14px"}
  //   >
  //     {children}
  //   </Td>
  // );

  // const TH = ({ children, ...restProps }) => (
  //   <Th
  //     border="1px solid rgba(0, 0, 0, 0.1)"
  //     p={isScreenLessThan1536px ? 1 : 3}
  //     fontFamily="Inter"
  //     fontWeight="extrabold"
  //     fontSize={isScreenLessThan1750px ? "11px" : "14px"}
  //     {...restProps}
  //   >
  //     {children}
  //   </Th>
  // );
  return (
    <Box
      width="37.1875vw"
      height="8.5925vh"
      border="1px solid grey"
      marginLeft="1.25vw"
      marginTop="1.11vh"
      fontFamily="inter"
      fontWeight={600}
      fontSize="0.7921vw"
    >
      <HStack
        justifyContent="space-between"
        width="100%"
        height="50%"
        borderBottom="1px solid #000"
      >
        <Box
          paddingStart="0.625vw"
          borderRight="1px solid gray"
          height="100%"
          lineHeight="4.29625vh"
          width="12.395vw"
          alignItems="center"
        >
          Number of Total cases
        </Box>
        <Box
          paddingStart="0.625vw"
          borderRight="1px solid gray"
          height="100%"
          lineHeight="4.29625vh"
          width="12.395vw"
          alignItems="center"
        >
          Pending cases
        </Box>
        <Box
          paddingStart="0.625vw"
          borderRight="1px solid gray"
          height="100%"
          lineHeight="4.29625vh"
          width="12.395vw"
          alignItems="center"
        >
          Submitted cases
        </Box>
      </HStack>
      <HStack
        justifyContent="space-between"
        width="100%"
        height="50%"
        borderBottom="1px solid #000"
      >
        <Box
          paddingStart="0.625vw"
          borderRight="1px solid gray"
          height="100%"
          lineHeight="4.29625vh"
          width="12.395vw"
          alignItems="center"
        >
          {total}
        </Box>
        <Box
          paddingStart="0.625vw"
          borderRight="1px solid gray"
          height="100%"
          lineHeight="4.29625vh"
          width="12.395vw"
          alignItems="center"
        >
          {total - completed}
        </Box>
        <Box
          paddingStart="0.625vw"
          borderRight="1px solid gray"
          height="100%"
          lineHeight="4.29625vh"
          width="12.395vw"
          alignItems="center"
        >
          {completed}
        </Box>
      </HStack>
    </Box>
  );
};

export default StatsTable;
