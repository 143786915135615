import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";
import "../../styles/newproject.css";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      className="logout__btn"
      backgroundColor="#E4E5E8"
      width="100px"
      height="32px"
      fontFamily="fira sans"
      fontWeight="semibold"
      fontSize="14px"
      marginTop="10px"
      color="#00153F"
      pos="absolute"
      right={255}
      border="0.5px solid #00153F"
      borderRadius={0}
      _hover={{ bg: "#00153F40" }}
      paddingTop="2px"
      onClick={() => logout({ returnTo: window.location.origin + "/login" })}
    >
      Log out
    </Button>
  );
};

export default LogoutButton;
