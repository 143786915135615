import React from "react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  Image,
} from "@chakra-ui/react";
import ProgressBar from "@ramonak/react-progress-bar";
import PropTypes from "prop-types";
import { Link as RouteLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import _ from "lodash";
import { isCaseViewable } from "../../hooks/utility";
import "../../styles/projectDetails.css";
import {
  useGetProjectReportQuery,
  useGetUserInfoQuery,
} from "../../state/api/medicalApi";
import CaseReport from "../Report/caseReport";
import newCasesIcon from "../../images/readerScreen/newCasesIcon.svg";
import nonCompletedProjectIcon from "../../images/readerScreen/notCompletedStatus.svg";
import completedProjectIcon from "../../images/readerScreen/completedStatus.svg";

const TasksAssigned = ({
  owner,
  progress,
  tasks,
  projectType,
  projectId,
  questionnaire,
  members,
  slideType,
  query,
}) => {
  const { user } = useAuth0();
  const id = user?.sub.substring(user?.sub.indexOf("|") + 1);
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const { data: report } = useGetProjectReportQuery({
    subClaim: user?.sub,
    projectId: projectId,
  });
  // const taskProgresss = {};
  // progress?.forEach((member) => {
  //   member.casesCompleted.map((c) => {
  //     if (!_.has(taskProgresss, c)) taskProgresss[c] = [];
  //     taskProgresss[c].push(member.user._id);
  //   });
  // });

  return (
    <Flex
      overflow="auto"
      direction="row"
      mb="4.25vh"
      width="83.51041vw"
      minW="768px"
      overflowY="auto"
      h="75.14vh"
    >
      <Box w="100%" px="0.938vw">
        <HStack
          w="100%"
          color="#000000"
          py="1.11vh"
          px="0.938vw"
          fontFamily="inter"
          fontSize="0.729vw"
          fontWeight="600"
        >
          <Text minW="5%">Sr.no</Text>
          <Text minW={projectType === "multiSlide" ? "15%" : "25%"}>
            {projectType === "multiSlide" ? "Cases" : "Slide Name"}
          </Text>
          {projectType === "multiSlide" ? (
            <>
              <Text minW="10%">Slide Name 1</Text>
              <Text minW="10%">Stain Type 1</Text>
              <Text minW="10%">Slide Name 2</Text>
              <Text minW="10%">Stain Type 2</Text>
            </>
          ) : (
            <Text minW={projectType === "multiSlide" ? "10%" : "15%"}>
              Stain Type
            </Text>
          )}
          <Text minW={projectType === "multiSlide" ? "10%" : "15%"}>Date</Text>
          <Text minW={projectType === "multiSlide" ? "15%" : "25%"}>
            Progress
          </Text>
          <Text>Report</Text>
        </HStack>
        {tasks
          .filter((task) => {
            if (query === "") {
              //if query is empty
              return task;
            } else if (task.name.toLowerCase().includes(query.toLowerCase())) {
              //returns filtered array
              return task;
            }
          })
          .map((task, index) => {
            return isCaseViewable(projectType, task?.slides.length) ? (
              <Link
                key={task?._id}
                style={{ textDecoration: "none" }}
                _focus={{ border: "none" }}
                as={RouteLink}
                to={{
                  pathname: `/${id}/project/${task?.projectId}/slideRedirect`,
                  state: {
                    caseId: task?._id,
                    questionnaire: questionnaire,
                    userIdToQuery: userInfo?.user._id,
                  },
                }}
              >
                <HStack
                  px="0.938vw"
                  w="100%"
                  key={task._id}
                  borderBottom="1px solid #151C25"
                  _hover={{
                    bg: isCaseViewable(projectType, task?.slides.length)
                      ? "#F8F8F5"
                      : "red.300",
                  }}
                  bgColor={
                    isCaseViewable(projectType, task?.slides.length)
                      ? "none"
                      : "red.100"
                  }
                  color="#151C25"
                  minH="6.66vh"
                  fontFamily="inter"
                  fontSize="0.729vw"
                  fontWeight="600"
                >
                  <Text minW="5%">{`${index + 1}.`}</Text>
                  <Flex
                    alignItems="center"
                    direction="row"
                    fontFamily="fira sans"
                    fontSize="0.937vw"
                    minW={projectType === "multiSlide" ? "15%" : "25%"}
                  >
                    <Image
                      src={newCasesIcon}
                      width="1vw"
                      height="1.8vh"
                      mr="0.83vw"
                    />

                    {task?.name}
                  </Flex>
                  {projectType === "multiSlide" ? (
                    <>
                      <Text minW="10%">{task?.slides[0].slideName}</Text>
                      <Text minW="10%">{slideType}</Text>
                      <Text minW="10%">{task?.slides[1].slideName}</Text>
                      <Text minW="10%">{slideType}</Text>
                    </>
                  ) : (
                    <Text minW="15%">{slideType}</Text>
                  )}
                  <Text minW={projectType === "multiSlide" ? "10%" : "15%"}>
                    {moment(task?.createdAt).format("DD/MMM/YYYY")}
                  </Text>

                  <Box minW={projectType === "multiSlide" ? "15%" : "25%"}>
                    {members?.map((member) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        _focus={{ border: "none" }}
                        as={RouteLink}
                        key={member._id}
                        to={{
                          pathname: `/${id}/project/${task?.projectId}/slideRedirect`,
                          state: {
                            caseId: task?._id,
                            questionnaire: questionnaire,
                            userIdToQuery: member._id,
                          },
                        }}
                      >
                        <Avatar
                          name={`${member.firstName} ${member.lastName}`}
                          size="sm"
                          border={
                            progress?.[task?._id]?.includes(member._id)
                              ? " 2px solid #14A568"
                              : "2px solid #B00020"
                          }
                          backgroundColor="#fff"
                          color="#000"
                          mr="1.041vw"
                        />
                      </Link>
                    ))}
                  </Box>

                  {/* {owner?.subClaim === user?.sub ? (
                <Flex minW="28%">
                  <Flex
                    h="44px"
                    direction="column"
                    bgColor="#D8D8D6"
                    pl="16px"
                    pr="10px"
                    justifyContent="center"
                  >
                    <Flex pr="10px" alignItems="center">
                      <Text
                        fontWeight="600"
                        fontFamily="fira sans"
                        fontSize="18px"
                        color={
                          progress?.[task?._id]?.length === members.length
                            ? "#05A787"
                            : progress?.[task?._id]?.length >
                              members.length * 0.25
                            ? "#FF9900"
                            : "#B00020"
                        }
                      >
                        {progress?.[task?._id]?.length > 0
                          ? progress?.[task?._id]?.length
                          : "0"}
                      </Text>
                      <Text fontWeight="600" fontFamily="inter" fontSize="14px">
                        /{members.length} Report submitted
                      </Text>
                    </Flex>
                    <ProgressBar
                      width={ifScreenlessthan1536px ? "140px" : "200px"}
                      height="3px"
                      completed={
                        progress?.[task?._id]?.length > 0
                          ? progress?.[task?._id]?.length
                          : 0
                      }
                      maxCompleted={members.length}
                      baseBgColor="rgba(140, 140, 140, 0.5)"
                      bgColor="#595959"
                      isLabelVisible={false}
                    />
                  </Flex>
                </Flex>
              ) : null} */}
                  <Flex alignItems="center">
                    <Box mr="0.520vw">
                      {task?.caseCompleted ? (
                        <Image
                          src={completedProjectIcon}
                          width="1vw"
                          height="1.5vh"
                        />
                      ) : (
                        <Image
                          src={nonCompletedProjectIcon}
                          width="1vw"
                          height="1.5vh"
                        />
                      )}
                    </Box>
                    <Text mr="0.2vw">View Report</Text>
                    <CaseReport
                      report={report}
                      reportType={projectType}
                      caseId={task?._id}
                      title="View Report"
                    />
                  </Flex>
                </HStack>
              </Link>
            ) : (
              <HStack
                key={task._id}
                minH="6.66vh"
                px="0.938vw"
                w="100%"
                borderBottom="1px solid #151C25"
                _hover={{
                  bg: isCaseViewable(projectType, task?.slides.length)
                    ? "#F8F8F5"
                    : "red.300",
                }}
                bgColor={
                  isCaseViewable(projectType, task?.slides.length)
                    ? "none"
                    : "red.100"
                }
                color="#151C25"
                fontFamily="inter"
                fontSize="0.729vw"
                fontWeight="600"
              >
                <Text minW="5%">{`${index + 1}.`}</Text>
                <Flex
                  alignItems="center"
                  minW="25%"
                  fontFamily="fira sans"
                  fontWeight="600"
                  fontSize="0.937vw"
                >
                  <Image
                    src={newCasesIcon}
                    width="1vw"
                    height="1.8vh"
                    mr="0.83vw"
                  />

                  {task?.name}
                </Flex>
                <Text minW="15%">{slideType}</Text>
                <Text minW="15%">
                  {moment(task?.createdAt).format("DD/MMM/YYYY")}
                </Text>

                <Box minW="25%">
                  {members?.map((member) => (
                    <Avatar
                      key={member._id}
                      name={`${member.firstName} ${member.lastName}`}
                      size="sm"
                      border={
                        progress?.[task?._id]
                          ? " 2px solid #0032A0"
                          : "2px solid #B00020"
                      }
                      mr="1.041vw"
                      backgroundColor="#fff"
                      color="#000"
                    />
                  ))}
                </Box>
              </HStack>
            );
          })}
      </Box>
    </Flex>
  );
};

TasksAssigned.propTypes = {
  cases: PropTypes.array,
};

export default TasksAssigned;
