import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Environment from "../../environment";
import {
  transformToCaseStats,
  transformQuestionnaireResponse,
  transformPIProjectProgress,
} from "./transformResponseUtility";

const medicalApiSlice = createApi({
  reducerPath: "medicalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Environment.USER_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().authState.token;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["projects", "invite", "lastTask", "response", "addCase"],
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: (body) => ({
        url: "get_user_information",
        method: "POST",
        body: body,
      }),
    }),
    getUserOrganization: builder.query({
      query: (body) => {
        return {
          url: "get_user_organization",
          method: "POST",
          body: body,
        };
      },
    }),
    getProjectInfo: builder.query({
      query: (body) => ({
        url: "get_project_information",
        method: "POST",
        body: body,
      }),
      providesTags: ["response", "addCase"],
    }),
    getUserProjectsInfo: builder.query({
      query: (body) => ({
        url: "get_user_status_for_all_projects",
        method: "POST",
        body: body,
      }),
      providesTags: ["response"],
    }),
    addNewUser: builder.mutation({
      query: (body) => ({
        url: "add_new_user",
        method: "POST",
        body: body,
      }),
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: "create_project",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["projects"],
    }),
    addMultipleMembersToProject: builder.mutation({
      query: (body) => ({
        url: "add_multiple_members_to_project",
        method: "POST",
        body: body,
      }),
    }),
    createOrganization: builder.mutation({
      query: (body) => ({
        url: "create_organization",
        method: "POST",
        body: body,
      }),
    }),
    updateLastViewed: builder.mutation({
      query: (body) => ({
        url: "update_last_viewed",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["projects"],
    }),
    getLastTask: builder.query({
      query: (body) => ({
        url: "get_user_last_case",
        method: "POST",
        body: body,
      }),
      providesTags: ["lastTask"],
    }),
    updateLastTask: builder.mutation({
      query: (body) => ({
        url: "update_last_worked_case",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["lastTask"],
    }),
    getCaseInfo: builder.query({
      query: (body) => ({
        url: "get_case_info",
        method: "POST",
        body: body,
      }),
    }),
    respondToProjectInvitation: builder.mutation({
      query: (body) => ({
        url: "respond_to_project_invitation",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["invite"],
    }),
    saveQuestionnaire: builder.mutation({
      query: (body) => ({
        url: "save_questionnaire_response",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["response"],
    }),
    getQuestionnaireResponse: builder.query({
      query: (body) => ({
        url: "get_questionnaire_response",
        method: "POST",
        body: body,
      }),
      transformResponse: transformQuestionnaireResponse,
      providesTags: ["response"],
    }),
    getUserInvitations: builder.query({
      query: (body) => ({
        url: "get_user_invitations",
        method: "POST",
        body: body,
      }),
      providesTags: ["invite"],
    }),
    addMultipleMembersToProjectByEmail: builder.mutation({
      query: (body) => ({
        url: "add_multiple_members_to_project_by_email",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["invite"],
    }),
    searchUser: builder.mutation({
      query: (body) => ({
        url: "search_user",
        method: "POST",
        body: body,
      }),
    }),
    getPIUserProjects: builder.query({
      query: (body) => ({
        url: "get_PI_user_projects",
        method: "POST",
        body: body,
      }),
      providesTags: ["invite", "projects"],
    }),
    getUserProjects: builder.query({
      query: (body) => ({
        url: "get_user_projects",
        method: "POST",
        body: body,
      }),
      providesTags: ["invite", "response"],
    }),
    saveUserAnnotations: builder.mutation({
      query: (body) => ({
        url: "save_user_annotations",
        method: "POST",
        body: body,
      }),
    }),
    getUserAnnotations: builder.mutation({
      query: (body) => ({
        url: "get_user_annotations",
        method: "POST",
        body: body,
      }),
    }),
    addCaseToProject: builder.mutation({
      query: (body) => ({
        url: "add_case_to_project",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["addCase"],
    }),
    getProjectReport: builder.query({
      query: (body) => ({
        url: "get_project_report",
        method: "POST",
        body: body,
      }),
      providesTags: ["response"],
    }),
    getReaderOverallStats: builder.query({
      query: (body) => ({
        url: "get_reader_overall_stats",
        method: "POST",
        body: body,
      }),
      transformResponse: transformToCaseStats,
      providesTags: ["invite", "response"],
    }),
    getReaderProjectStats: builder.query({
      query: (body) => ({
        url: "get_reader_project_stats",
        method: "POST",
        body: body,
      }),
      transformResponse: transformToCaseStats,
      providesTags: ["invite", "response"],
    }),
    getPIProjectProgress: builder.query({
      query: (body) => ({
        url: "get_pi_project_progress_by_case",
        method: "POST",
        body: body,
      }),
      transformResponse: transformPIProjectProgress,
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetUserOrganizationQuery,
  useAddNewUserMutation,
  useCreateProjectMutation,
  useAddMultipleMembersToProjectMutation,
  useCreateOrganizationMutation,
  useGetProjectInfoQuery,
  useGetUserProjectsInfoQuery,
  useUpdateLastViewedMutation,
  useGetLastTaskQuery,
  useGetCaseInfoQuery,
  useRespondToProjectInvitationMutation,
  useSaveQuestionnaireMutation,
  useUpdateLastTaskMutation,
  useGetQuestionnaireResponseQuery,
  useGetUserInvitationsQuery,
  useSearchUserMutation,
  useAddMultipleMembersToProjectByEmailMutation,
  useGetPIUserProjectsQuery,
  useGetUserProjectsQuery,
  useSaveUserAnnotationsMutation,
  useGetUserAnnotationsMutation,
  useAddCaseToProjectMutation,
  useGetProjectReportQuery,
  useGetReaderOverallStatsQuery,
  useGetReaderProjectStatsQuery,
  useGetPIProjectProgressQuery,
} = medicalApiSlice;

export default medicalApiSlice;
