import React, { useState } from "react";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import { FiMoreVertical } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";
import { VscBellDot } from "react-icons/vsc";
import {
  Flex,
  Text,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
  Input,
  Box,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";

const ReaderHeader = ({ setQuery }) => {
  const { user, logout } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });

  return (
    <Flex
      bgColor="#E4E5E8"
      alignItems="center"
      justifyContent="space-between"
      h="8.33vh"
      maxH="8.333vh"
      minH="8.333vh"
      pl="3.072vw"
    >
      <Text fontFamily="fira sans" fontWeight="500" fontSize="1.25vw">
        {`Welcome Dr. ${userInfo?.user.firstName}`}
      </Text>
      <Flex alignItems="center">
        <Box>
          <InputGroup w="16.66vw" size="sm" background="#F8F8F5" mr="1.562vw">
            <InputLeftElement
              pointerEvents="none"
              children={<BsSearch color="black" opacity="0.4" />}
            />
            <Input
              color="black"
              backgroundColor="white"
              borderRadius="0px"
              type="text"
              placeholder="Enter text here"
              borderColor="rgba(236, 236, 236, 1)"
              onChange={(event) => setQuery(event.target.value)}
            />
          </InputGroup>
        </Box>
        <Icon as={VscBellDot} mr="1.0675vw" />
        <Menu>
          <MenuButton as={IconButton} icon={<FiMoreVertical />} mr="1.125vw" />
          <MenuList bgColor="#ECECEC" borderRadius="0px">
            <MenuItem
              fontFamily="inter"
              fontWeight="600"
              fontSize="0.729vw"
              _focus={{ bgColor: "#fff" }}
            >
              Manage your account
            </MenuItem>
            <MenuItem
              fontFamily="inter"
              fontWeight="600"
              fontSize="0.729vw"
              _focus={{ bgColor: "#fff" }}
              onClick={() =>
                logout({ returnTo: window.location.origin + "/login" })
              }
            >
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default ReaderHeader;
