import React, { useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import Popup from "../Popup/popup";
import newProjectIcon from "../../images/new-project-images/projectNew.svg";
import helpIcon from "../../images/new-project-images/help.svg";

const ReaderMenu = () => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const [popup, setPopup] = useState(false);
  const handlePopup = () => {
    setPopup(!popup);
  };

  return (
    <Box bgColor="#E4E5E8" w="15.885vw" h="100vh">
      <Flex direction="column" py="2.870vh">
        <Flex borderBottom="2px solid rgba(140, 140, 140, 0.4)" pb="4.074vh">
          <Image
            borderRadius="full"
            boxSize="2.708vw"
            mx="1vw"
            src={user?.picture}
            alt="User"
          />
          <Flex direction="column">
            <Text
              color="black"
              fontFamily="fira sans"
              fontWeight="600"
              fontSize="1.0416vw"
            >
              {"Dr. " +
                userInfo?.user.firstName +
                " " +
                userInfo?.user.lastName}
            </Text>

            <Text
              color="black"
              marginTop="0.3125vh"
              fontFamily="inter"
              fontSize="0.625vw"
            >
              {userInfo?.user.emailAddress}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="row"
          _hover={{ bg: "#F8F8F5" }}
          alignItems="center"
          _active={{ bgColor: "#fff" }}
          _selected={{ bgColor: "#fff" }}
          h="4.814vh"
          my="1.666vh"
          pl="0.937vw"
        >
          <Image
            src={newProjectIcon}
            marginRight="0.584vw"
            width="0.69vw"
            height="1.956vh"
          />
          <Text fontWeight="semibold" fontFamily="inter" fontSize="0.729vw">
            Clinical Study
          </Text>
        </Flex>
        <Flex
          direction="row"
          _hover={{ bg: "#F8F8F5" }}
          alignItems="center"
          _active={{ bgColor: "#fff" }}
          _selected={{ bgColor: "#fff" }}
          pl="0.937vw"
          h="4.814vh"
          borderTop="2px solid rgba(140, 140, 140, 0.4)"
          onClick={handlePopup}
        >
          <Image
            src={helpIcon}
            marginRight="0.584vw"
            width="0.69vw"
            height="1.956vh"
          />
          <Text fontWeight="semibold" fontFamily="inter" fontSize="0.729vw">
            Help
          </Text>
        </Flex>
      </Flex>
      <Popup
        handlePopup={() => {
          handlePopup();
        }}
        popup={popup}
      />
    </Box>
  );
};

export default ReaderMenu;
