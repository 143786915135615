import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import AdjustmentBar from "../AdjustmentBar/adjustmentBar";
import LayoutAppBody from "./body";
import LayoutInnerBody from "./innerbody";
import LayoutOuterBody from "./outerbody";
import LayoutAppSidebar from "./sidebar";
import Div100vh from "react-div-100vh";
import ViewerFactory from "../Viewer/viewerFactory";
import { resetFabricOverlay } from "../../state/reducers/fabricOverlayReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useUpdateLastTaskMutation } from "../../state/api/medicalApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { resetViewerIds } from "../../state/reducers/viewerReducer";
import { useMediaQuery } from "@chakra-ui/media-query";
import { resetResponse } from "../../state/reducers/slideQnaReducer";

const LayoutApp = () => {
  // const { handleEvent } = useKeyboardEvents();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const location = useLocation();
  const [updateLastTask] = useUpdateLastTaskMutation();

  useEffect(() => {
    return () => {
      dispatch(resetViewerIds());
      dispatch(resetFabricOverlay());
      dispatch(resetResponse());
      updateLastTask({
        subClaim: user?.sub,
        caseId: location?.state.caseId,
      });
    };
  }, []);

  const [sidebar, setSidebar] = useState(true);
  const [navbar, setNavbar] = useState(true);
  const [ifBiggerScreen] = useMediaQuery("(min-width:1920px)");

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const showNavbar = () => {
    setNavbar(!navbar);
  };

  return (
    <Flex as={Div100vh} h="100vh" direction="column">
      <LayoutOuterBody>
        <AdjustmentBar showSidebar={() => showSidebar()} sidebar={sidebar} />
        <LayoutInnerBody>
          {sidebar ? <LayoutAppSidebar /> : null}
          <LayoutAppBody>
            <ViewerFactory />
          </LayoutAppBody>
        </LayoutInnerBody>
      </LayoutOuterBody>
    </Flex>
  );
};

export default LayoutApp;
