import React from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { BsSearch } from "react-icons/bs";
import { BiTime } from "react-icons/bi";

const Search = ({ w, mr }) => {
  return (
    <InputGroup
      w={300}
      size="sm"
      marginTop="10px"
      pos="absolute"
      right={380}
      background="#F8F8F5"
    >
      <InputLeftElement
        pointerEvents="none"
        children={<BsSearch color="black" opacity="0.4" />}
      />
      <Input
        color="black"
        backgroundColor="white"
        borderRadius="0px"
        type="text"
        placeholder="Enter text here"
        borderColor="rgba(236, 236, 236, 1)"
      />
    </InputGroup>
  );
};

export default Search;
