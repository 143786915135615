import React, { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Select,
  Text,
  VStack,
  HStack,
  IconButton,
  Popover,
  Spacer,
  Button,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSlides,
  resetCases,
  updateCases,
  updateFile,
} from "../../state/reducers/newProjectReducer";
import PreviewSlides from "./previewSlides";
import Projectdetails from "./projectdetails";
import InfoLink from "./infoLink";
import SelectSlideModal from "./selectSlideModal";
import UploadIcon from "../../images/new-project-images/UploadIcon.svg";
import _ from "lodash";
import SelectSlideHelper from "./selectSlideHelper";

const SlideFileUpload = () => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const { projectDetails, uploadedFile, slides } = useSelector(
    (state) => state.newProjectState
  );
  const [selected, setSelected] = useState({});
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const inputRef = useRef();

  const [isWrongFile, setIsWrongFile] = useState(false);

  const handleDropFile = (csvFile, file) => {
    if (
      csvFile.length === 0 ||
      (projectDetails.projectType === "singleSlide" &&
        csvFile[0].data.length !== 2) ||
      (projectDetails.projectType === "multiSlide" &&
        csvFile[0].data.length !== 3)
    ) {
      setIsWrongFile(true);
      dispatch(resetCases());
      inputRef.current.removeFile();
      return;
    }
    const cases = [];
    const projectSlides = [];
    for (let col of csvFile) {
      // check if slide in csv file is present in list of user slides
      const slide1 = userInfo?.user.slides.find(
        (e) => e.slideName === col.data[1]
      );
      const slides = [slide1?._id ? slide1 : null];
      // id slide found in user slides create an object with id
      // else create an empty object
      const caseSlides = [
        slide1?._id
          ? {
              slideName: slide1?.slideName,
              awsImageBucketUrl: slide1?.awsImageBucketUrl,
            }
          : "",
      ];

      // if project is multiSlide then check second slide too
      if (col.data.length === 3) {
        const slide2 = userInfo?.user.slides.find(
          (e) => e.slideName === col.data[2]
        );

        caseSlides.push(
          slide2?._id
            ? {
                slideName: slide2?.slideName,
                awsImageBucketUrl: slide2?.awsImageBucketUrl,
              }
            : ""
        );
        slides.push(slide2?._id ? slide2 : null);
      }
      cases.push({ name: col.data[0], slides: caseSlides });
      projectSlides.push(slides);
    }
    dispatch(updateCases(cases));
    dispatch(updateFile(file.name));
    dispatch(addSlides(projectSlides));
    setIsWrongFile(false);
  };

  const handleRemoveFile = () => {
    dispatch(resetCases());
  };

  const handleFileError = () => {};

  const handleClickOnSlide = (slide) => {
    if (_.has(selected, slide._id) && selected[slide._id]) {
      setSelected({ ...selected, [slide._id]: null });
      setCount(count - 1);
    } else {
      setSelected({ ...selected, [slide._id]: slide });
      setCount(count + 1);
    }
    // setSlideUrl(slide.awsImageBucketUrl)
    // setSlideName(slide.slideName)
  };

  // console.log(slideUrl)
  // console.log(slideName)
  const handleSelectedSlides = () => {
    const projectSlides = [];
    const cases = [];

    _.values(selected).map((value) => {
      if (value) {
        projectSlides.push([value]);
      }
    });
    console.log(projectSlides);
    projectSlides.map((slide, index) => {
      cases.push({
        name: `case${index + 1}`,
        slides: [
          {
            slideName: slide[0].slideName,
            awsImageBucketUrl: slide[0].awsImageBucketUrl,
          },
        ],
      });
    });
    dispatch(addSlides(projectSlides));
    dispatch(updateCases(cases));
  };

  return (
    <Box w={700} fontFamily="inter" color="#000">
      <VStack spacing={8} maxW={600}>
        <SelectSlideHelper
          slides={userInfo?.user.slides}
          handleClickOnSlide={handleClickOnSlide}
          handleSelectedSlides={handleSelectedSlides}
          selected={selected}
          count={count}
          disabled={projectDetails.projectType === "multiSlide"}
        />
        <FormControl>
          <FormLabel color="#000" fontSize="14px">
            Select CSV File
          </FormLabel>
          <HStack width="733px">
            <CSVReader
              ref={inputRef}
              onDrop={handleDropFile}
              onError={handleFileError}
              onRemoveFile={handleRemoveFile}
              addRemoveButton
              style={{
                dropArea: {
                  padding: 8,
                  width: "100%",
                  fontFamily: "inter",
                  fontSize: "14px",
                  fontWeight: "400",
                  border: "1px solid rgba(21, 28, 37, 1)",
                  marginLeft: "-2px",
                  borderRadius: "0px",
                },
                dropFile: {
                  width: 120,
                  height: 100,
                },
              }}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
            <InfoLink />
          </HStack>
          <Text fontSize={20} p={2} color="#3965C5">
            {uploadedFile && `Uploaded File : ${uploadedFile}`}
          </Text>
          <Text fontSize={20} color="red" px={2}>
            {isWrongFile && "Check the file: Wrong format"}
          </Text>
        </FormControl>
      </VStack>
      {slides.length > 0 && (
        <Box w="100%">
          <Text mb={2}>Preview Slides</Text>
          <PreviewSlides projectSlides={slides} />
        </Box>
      )}
    </Box>
  );
};

export default SlideFileUpload;
