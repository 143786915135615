import React, { useState, useRef, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Select,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsCheckSquareFill, BsFillXSquareFill } from "react-icons/bs";
import {
  useGetProjectInfoQuery,
  useSaveQuestionnaireMutation,
} from "../../state/api/medicalApi";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addResponse,
  resetResponse,
} from "../../state/reducers/slideQnaReducer";
import { useDispatch, useSelector } from "react-redux";
import { getSlideUrl, getUserId, isCaseViewable } from "../../hooks/utility";
import _ from "lodash";

const AnswersPreview = ({
  questionnaire,
  firstSlideQna,
  secondSlideQna,
  SlideId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const { user } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const { currentViewer } = useSelector((state) => state.viewerState);
  const dispatch = useDispatch();
  const { data: project } = useGetProjectInfoQuery({
    subClaim: user?.sub,
    projectId: location.state.projectId,
  });
  const [saveQuestionnaire] = useSaveQuestionnaireMutation();
  const url1 = getSlideUrl(location?.state.viewerIds[0].awsImageBucketUrl);
  const url2 =
    project?.type === "multiSlide"
      ? getSlideUrl(location?.state.viewerIds[1].awsImageBucketUrl)
      : "";

  const finalSubmit = async () => {
    const firstResponses = _.values(firstSlideQna?.response).filter(
      (resp) => resp.choiceType
    );
    await saveQuestionnaire({
      subClaim: user?.sub,
      questionnaireId: questionnaire._id,
      slideId: SlideId[0]._id,
      caseId: location?.state.caseId,
      responses: firstResponses,
    });
    if (project?.type === "multiSlide") {
      const secondResponses = _.values(secondSlideQna?.response).filter(
        (resp) => resp.choiceType
      );
      await saveQuestionnaire({
        subClaim: user?.sub,
        questionnaireId: questionnaire._id,
        slideId: SlideId[1]._id,
        caseId: location?.state.caseId,
        responses: secondResponses,
      });
    }

    // find index of current case
    const currentIndex = project?.cases.findIndex(
      (projectCase) => projectCase._id === location?.state.caseId
    );

    if (
      currentIndex + 1 === project?.cases.length ||
      !isCaseViewable(
        project?.type,
        project?.cases[currentIndex + 1].slides.length
      )
    )
      history.goBack();
    else {
      const id = getUserId(user);
      history.replace({
        pathname: `/${id}/project/${location?.state.projectId}/slideRedirect`,
        state: {
          caseId: project?.cases[currentIndex + 1]._id,
          projectId: location?.state.projectId,
          slides: project?.cases[currentIndex + 1].slides,
          slideType: project?.slideType,
          questionnaire: project?.questionnaire,
        },
      });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetResponse());
    };
  }, []);

  return (
    <>
      <Box bgColor="rgba(236, 236, 236, 1)">
        {user?.sub !== project?.owner.subClaim ? (
          <Button
            my={5}
            backgroundColor="#00153F"
            h="32px"
            borderRadius={0}
            width="10em"
            color="white"
            fontWeight="500"
            fontSize="16px"
            fontFamily="inter"
            ml="20px"
            _hover={{ bg: "#2166fc" }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Submit
          </Button>
        ) : null}
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        size="3xl"
        width="70%"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogContent borderRadius="10px">
          <AlertDialogHeader
            backgroundColor="rgba(236, 236, 236, 1)"
            color="black"
            padding="5px"
            borderTopRadius="10px"
          >
            <Text marginLeft="1em" size="sm">
              Preview
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody space={2} paddingTop="2em" paddingBottom="1em">
            <HStack>
              <Flex
                direction="column"
                backgroundColor="rgba(236, 236, 236, 1)"
                color="black"
                borderRadius="3px"
                padding="1em"
                textAlign="left"
                fontFamily="inter"
                fontSize="16px"
                width="50%"
              >
                {questionnaire?.questions.map((question, index) => (
                  <Box key={question._id}>
                    {question._id === questionnaire?.questions[1]._id &&
                    firstSlideQna?.qna?.[questionnaire?.questions[0]._id] !==
                      "No" ? (
                      <Text color="grey">{`Q${index + 1} ${
                        question.questionText
                      }`}</Text>
                    ) : question.questionText === "Hepatocellular ballooning" &&
                      question.questionType === "text" ? (
                      ["(1)Few", "(2)Many"].includes(
                        firstSlideQna?.qna?.[questionnaire?.questions[4]._id]
                      ) ? (
                        <Text marginTop="0.5em" marginLeft="2em">
                          <Icon
                            as={
                              firstSlideQna?.qna[question._id]
                                ? BsCheckSquareFill
                                : BsFillXSquareFill
                            }
                            marginRight={1}
                            color={
                              firstSlideQna?.qna[question._id]
                                ? "#3965C6"
                                : "red.400"
                            }
                            w={3}
                            h={3}
                          />
                          {firstSlideQna?.qna[question._id] ?? "Text missing"}
                        </Text>
                      ) : null
                    ) : (
                      <>
                        <Text>{`Q${index + 1} ${question.questionText}`}</Text>
                        <Text marginTop="0.5em" marginLeft="2em">
                          <Icon
                            as={
                              firstSlideQna?.qna[question._id]
                                ? BsCheckSquareFill
                                : BsFillXSquareFill
                            }
                            marginRight={1}
                            color={
                              firstSlideQna?.qna[question._id]
                                ? "#3965C6"
                                : "red.400"
                            }
                            w={3}
                            h={3}
                          />
                          {firstSlideQna?.qna[question._id]
                            ? typeof firstSlideQna?.qna?.[question._id] ===
                              "string"
                              ? firstSlideQna?.qna[question._id]
                              : firstSlideQna?.qna[question._id].join(", ")
                            : "Required Field"}
                        </Text>
                      </>
                    )}
                  </Box>
                ))}
              </Flex>
              <Spacer />
              <Flex width="50%" direction="column">
                <Text
                  fontSize="16px"
                  fontFamily="inter"
                  fontWeight="500"
                  color="black"
                >
                  Are you sure you want to submit the answers?
                </Text>
                <Image
                  marginY="1em"
                  height="10em"
                  width="18em"
                  src={url1}
                  marginTop="10px"
                />
                <Text fontSize="16px" fontFamily="inter" color="black">
                  {project?.slideType} Slide
                </Text>
                <Text fontSize="20px" fontFamily="inter" color="black">
                  {project?.name}
                </Text>
                <Text
                  fontSize="14px"
                  color="rgba(0, 0, 0, 0.4)"
                  marginBottom="1em"
                >
                  {SlideId[0]._id}
                </Text>
              </Flex>
            </HStack>
            {project?.type === "multiSlide" ? (
              <HStack>
                <Flex
                  direction="column"
                  backgroundColor="rgba(236, 236, 236, 1)"
                  color="black"
                  borderRadius="3px"
                  padding="1em"
                  textAlign="left"
                  fontFamily="inter"
                  fontSize="16px"
                  width="50%"
                >
                  {questionnaire?.questions.map((question, index) => (
                    <Box key={question._id}>
                      {question._id === questionnaire?.questions[1]._id &&
                      secondSlideQna?.qna?.[questionnaire?.questions[0]._id] !==
                        "No" ? (
                        <Text color="grey">{`Q${index + 1} ${
                          question.questionText
                        }`}</Text>
                      ) : question.questionText ===
                          "Hepatocellular ballooning" &&
                        question.questionType === "text" ? (
                        ["(1)Few", "(2)Many"].includes(
                          secondSlideQna?.qna?.[questionnaire?.questions[4]._id]
                        ) ? (
                          <Text marginTop="0.5em" marginLeft="2em">
                            <Icon
                              as={
                                secondSlideQna?.qna[question._id]
                                  ? BsCheckSquareFill
                                  : BsFillXSquareFill
                              }
                              marginRight={1}
                              color={
                                secondSlideQna?.qna[question._id]
                                  ? "#3965C6"
                                  : "red.400"
                              }
                              w={3}
                              h={3}
                            />
                            {secondSlideQna?.qna[question._id] ??
                              "Text missing"}
                          </Text>
                        ) : null
                      ) : (
                        <>
                          <Text>{`Q${index + 1} ${
                            question.questionText
                          }`}</Text>
                          <Text marginTop="0.5em" marginLeft="2em">
                            <Icon
                              as={
                                secondSlideQna?.qna[question._id]
                                  ? BsCheckSquareFill
                                  : BsFillXSquareFill
                              }
                              marginRight={1}
                              color={
                                secondSlideQna?.qna[question._id]
                                  ? "#3965C6"
                                  : "red.400"
                              }
                              w={3}
                              h={3}
                            />
                            {secondSlideQna?.qna[question._id]
                              ? typeof secondSlideQna?.qna?.[question._id] ===
                                "string"
                                ? secondSlideQna?.qna[question._id]
                                : secondSlideQna?.qna[question._id].join(", ")
                              : "Required Field"}
                          </Text>
                        </>
                      )}
                    </Box>
                  ))}
                </Flex>
                <Spacer />
                <Flex width="50%" direction="column">
                  <Image
                    marginY="1em"
                    height="10em"
                    width="18em"
                    src={url2}
                    marginTop="10px"
                  />
                  <Text fontSize="16px" fontFamily="inter" color="black">
                    {project?.slideType} Slide
                  </Text>
                  <Text fontSize="20px" fontFamily="inter" color="black">
                    {project?.name}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="rgba(0, 0, 0, 0.4)"
                    marginBottom="1em"
                  >
                    {SlideId[1]._id}
                  </Text>
                </Flex>
              </HStack>
            ) : (
              ""
            )}
            <HStack justifyContent="flex-end">
              <Button
                variant="solid"
                width="10em"
                color="#00153F"
                backgroundColor="#ECECEC10"
                border="1px solid #00153F"
                borderRadius={0}
                _hover={{
                  backgroundColor: "#ECECEC30",
                }}
                onClick={() => onClose()}
                fontSize="16px"
                fontFamily="inter"
                fontWeight="bold"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                width="10em"
                color="white"
                backgroundColor="#00153F"
                borderRadius={0}
                onClick={finalSubmit}
                disabled={
                  project?.type === "multiSlide"
                    ? questionnaire?.questions.length * 2 !==
                      _.keys(firstSlideQna?.response).length +
                        _.keys(secondSlideQna?.response).length
                    : questionnaire?.questions.length !==
                      _.keys(firstSlideQna?.response).length
                }
                _hover={{
                  backgroundColor: "#00153F90",
                }}
                fontSize="16px"
                fontFamily="inter"
                fontWeight="bold"
              >
                Save & Next
              </Button>
            </HStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AnswersPreview;
