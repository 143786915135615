import React from "react";
import ReactPDF, {
  Page,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";

const ReportDocument = ({
  report,
  reportType,
  caseId = null,
  userId = null,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: "10px 0px",
      flexDirection: "column",
      backgroundColor: "white",
      fontSize: "16px",
    },
    titleText: {
      fontSize: "12px",
      marginTop: "5px",
    },
    section: {
      margin: 10,
      padding: 10,
      width: "500px",
      borderWidth: "2px",
    },
    caseView: {
      margin: 10,
      padding: 10,
      fontSize: 14,
    },
    caseBody: {
      fontSize: "12px",
      marginTop: "5px",
      marginLeft: "5px",
    },
    slideView: {
      margin: "5px",
      fontSize: "12px",
    },
    responseView: {
      margin: 10,
      flexDirection: "row",
      // borderTopWidth: "1px",
      // borderLeftWidth: "1px",
      alignSelf: "flex-start",
    },
    tableHeading: {
      borderBottomWidth: "1px",
      padding: "5px",
    },
    tableCol: {
      flexDirection: "column",
      borderRightWidth: "1px",
    },
    tableRow: {
      padding: "5px",
      borderBottomWidth: "1px",
      fontSize: "12px",
      textAlign: "center",
    },
    questionRow: {
      padding: "5px",
    },
    responseRow: {
      padding: "2px 10px 5px 10px",
    },
    tableContent: {
      // padding: 10,
      marginBottom: 20,
      width: "500px",
      borderWidth: "1px",
    },
  });

  const UserResponse = ({ member, slide, questionnaire }) => {
    let slideResponse = {};
    slide.questionnaireResponses.forEach((resp) => {
      slideResponse[resp.choice.question?._id] = resp.choice.choiceText;
    });
    return (
      <View style={styles.tableContent} wrap={false}>
        <ReactPDF.Text style={styles.tableHeading}>
          {`${member.firstName} ${member.lastName}`}
        </ReactPDF.Text>

        {slide.questionnaireResponses.find(
          (slideResponse) => slideResponse.user._id === member._id
        )
          ? questionnaire.questions.map((question, index) => {
              return (
                <View key={question._id}>
                  {question.questionText === "Hepatocellular ballooning" &&
                  question.questionType === "text" ? null : (
                    <ReactPDF.Text style={styles.questionRow}>
                      {`Q.${index + 1}${" "}${question.questionText}`}
                    </ReactPDF.Text>
                  )}
                  <ReactPDF.Text style={styles.responseRow}>
                    {slideResponse[question._id]}
                  </ReactPDF.Text>
                </View>
              );
            })
          : questionnaire.questions.map((question, index) => {
              return (
                <View key={question._id}>
                  {question.questionText === "Hepatocellular ballooning" &&
                  question.questionType === "text" ? null : (
                    <>
                      <ReactPDF.Text style={styles.questionRow}>
                        {`Q.${index + 1}${" "}${question.questionText}`}
                      </ReactPDF.Text>
                      <ReactPDF.Text style={styles.responseRow}>
                        -
                      </ReactPDF.Text>
                    </>
                  )}
                </View>
              );
            })}
      </View>
    );
  };

  const CaseView = ({ report, projectCase }) => (
    <View style={styles.caseView}>
      <ReactPDF.Text>Case Name: {projectCase.name}</ReactPDF.Text>
      <ReactPDF.Text style={styles.caseBody}>
        Creation Date: {moment(projectCase?.createdAt).format("MMM DD, YYYY")}
      </ReactPDF.Text>
      {projectCase?.slides.map((slide, index) => {
        return (
          <View key={slide._id}>
            <ReactPDF.Text style={styles.caseBody}>
              Slide {index + 1}
            </ReactPDF.Text>
            <ReactPDF.Text style={styles.caseBody}>Responses :</ReactPDF.Text>
            <View style={styles.responseView}>
              <View wrap>
                {/* <ReactPDF.Text s>
                  Questions
                </ReactPDF.Text> */}
                {/* {report.questionnaire.questions.map((question) => {
                  return (
                    <> */}

                {userId ? (
                  <UserResponse
                    member={userId}
                    slide={slide}
                    questionnaire={report?.questionnaire}
                  />
                ) : (
                  report?.members.map((member) => {
                    return (
                      <UserResponse
                        key={member?._id}
                        member={member}
                        slide={slide}
                        questionnaire={report?.questionnaire}
                      />
                    );
                  })
                )}
                {/* </>
                  );
                })} */}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={false}>
        <View style={styles.section}>
          <ReactPDF.Text> Project Name: {report?.name}</ReactPDF.Text>
          <ReactPDF.Text style={styles.titleText}>
            Creation Date: {moment(report?.createdAt).format("MMM DD, YYYY")}
          </ReactPDF.Text>
          <ReactPDF.Text style={styles.titleText}>
            {`Project Investigator: ${report?.owner?.firstName} ${report?.owner?.lastName}`}
          </ReactPDF.Text>
          <ReactPDF.Text style={styles.titleText}>
            {`Report Type: ${
              reportType === "multiSlide" ? "Multi-Slide" : "Questionnaire"
            }`}
          </ReactPDF.Text>
        </View>
        {caseId
          ? report?.cases.map((projectCase) => {
              if (projectCase._id === caseId)
                return (
                  <CaseView
                    key={projectCase?._id}
                    report={report}
                    projectCase={projectCase}
                  />
                );
            })
          : report?.cases.map((projectCase) => (
              <CaseView
                key={projectCase._id}
                report={report}
                projectCase={projectCase}
              />
            ))}
      </Page>
    </Document>
  );
};

export default ReportDocument;
