import { React, useState } from "react";
import {
  Box,
  Button,
  Select,
  Input,
  Text,
  Textarea,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCases,
  resetNewProject,
  updateProject,
} from "../../state/reducers/newProjectReducer";

const Projectdetails = () => {
  const [activeOption, setActiveOption] = useState("projectDetails");
  const { projectDetails } = useSelector((state) => state.newProjectState);
  const dispatch = useDispatch();

  const handleProjectDetails = (e) => {
    dispatch(updateProject({ name: e.target.name, value: e.target.value }));
    if (e.target.name === "projectType") dispatch(resetCases());
  };

  return (
    <>
      <Box fontFamily="inter">
        <Box>
          <Text
            htmlFor="project_title"
            color="#000"
            fontSize="14px"
            paddingBottom="16px"
            paddingLeft="28px"
          >
            Project Title
          </Text>
          <Input
            type="text"
            id="project_title"
            name="projectName"
            value={projectDetails.projectName}
            width={728}
            height={42}
            ml="28px"
            borderRadius={0}
            bg="#FFF"
            borderColor="#000"
            fontSize="14px"
            placeholder="Eg: Digital Pathology"
            onChange={(e) => handleProjectDetails(e)}
          ></Input>
          <Text
            htmlFor="project_desc"
            paddingTop={6}
            color="#000"
            fontSize="14px"
            paddingBottom="16px"
            ml="28px"
          >
            Project Description
          </Text>
          <Textarea
            id="project_desc"
            name="projectDescription"
            value={projectDetails.projectDescription}
            width={728}
            height={160}
            top="0"
            ml="28px"
            fontSize="14px"
            bg="#FFF"
            borderRadius={0}
            borderColor="#000"
            resize={"none"}
            placeholder="Eg: Write a one- or two-paragraph explanation of what the project aims to accomplish"
            onChange={(e) => handleProjectDetails(e)}
          ></Textarea>
          <HStack pl="28px">
            <VStack align="left">
              <Text
                htmlFor="project_type"
                paddingTop="28px"
                color="#000"
                // fontSize="16px"
                style={{ fontSize: "14px" }}
              >
                Report Type
              </Text>
              <Select
                variant="outline"
                id="project_type"
                name="projectType"
                value={projectDetails.projectType}
                onChange={(e) => handleProjectDetails(e)}
                fontSize="14px"
                width={350}
                bg="#FFF"
                borderColor="#000"
                borderRadius={0}
                color="#00153F"
              >
                <option value="singleSlide">Questionnaire</option>
                <option value="multiSlide">Multi-Slide</option>
              </Select>
            </VStack>
            <VStack pl={5} align="left">
              <Text
                htmlFor="slide_type"
                paddingTop="28px"
                color="#000"
                fontSize="14px"
              >
                Stain Type
              </Text>
              <Select
                id="slide_type"
                name="slideType"
                value={projectDetails.slideType}
                width={350}
                bg="#FFF"
                borderColor="#000"
                color="#00153F"
                fontSize="14px"
                borderRadius={0}
                onChange={(e) => handleProjectDetails(e)}
              >
                <option value="H&E">H&E</option>
                <option value="trichrome">Trichrome</option>
              </Select>
            </VStack>
          </HStack>
        </Box>
      </Box>
    </>
  );
};

export default Projectdetails;
