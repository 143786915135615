import React, { useState } from "react";
import { useSelector } from "react-redux";
import { resetNewProject } from "../../state/reducers/newProjectReducer";
import "../../styles/newproject.css";
import {
  Box,
  Text,
  Select,
  Input,
  HStack,
  VStack,
  Radio,
  RadioGroup,
  Stack,
  Divider,
} from "@chakra-ui/react";
import DummyQuestionnaire from "../Qna/dummyQuestionnaire";
import CustomQuestionnaire from "./customQuestionnaire";
import TextType from "../Qna/textType";
import TextboxQuesionnaire from "./textBoxQuesionnaire";

const CreateQuestionnaire = () => {
  const [questionState, setQuestionState] = useState("add");
  const handleState = () => {
    setQuestionState("add");
  };
  const { projectDetails, questions } = useSelector(
    (state) => state.newProjectState
  );

  return (
    <>
      <Box color="#000" fontFamily="inter" pl="28px">
        <Text fontSize="14px">Slide Type</Text>
        <HStack justifyContent="space-between" mr="28px">
          <Text
            mt="16px"
            fontSize="20px"
            fontWeight="600"
            fontFamily="fira sans"
            pb={4}
          >
            {projectDetails.slideType} Slides
          </Text>
          <Select
            id="select"
            fontFamily="inter"
            bgColor="#fff"
            fontSize="14px"
            focusBorderColor="none"
            borderColor="#00153F"
            size="sm"
            width="150px"
            value={questionState}
            onChange={(e) => setQuestionState(e.target.value)}
          >
            <option value="add" hidden>
              Add question
            </option>
            <option
              value="Radio Button"
              style={{ backgroundColor: "#ECECEC", height: "30px" }}
            >
              Radio Button
            </option>
            <option
              value="MCQ Button"
              style={{ backgroundColor: "#ECECEC", height: "30px" }}
            >
              MCQ Button
            </option>
            <option
              value="Input box"
              style={{ backgroundColor: "#ECECEC", height: "30px" }}
            >
              Input box
            </option>
          </Select>
        </HStack>
        <DummyQuestionnaire
          direction="row"
          questions={questions}
          slidetype={projectDetails.slideType}
        />
      </Box>
      {questionState === "Radio Button" ? (
        <CustomQuestionnaire
          handleCustomQuestionnaire={handleState}
          questionState={questionState === "Radio Button" ? "radio" : ""}
        />
      ) : (
        ""
      )}
      {questionState === "MCQ Button" ? (
        <CustomQuestionnaire
          handleCustomQuestionnaire={handleState}
          questionState={questionState === "MCQ Button" ? "checkbox" : ""}
        />
      ) : (
        ""
      )}
      {questionState === "Input box" ? (
        <TextboxQuesionnaire
          handleTextboxQuestionnaire={handleState}
          questionState={questionState === "Input box" ? "text" : ""}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CreateQuestionnaire;
