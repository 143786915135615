import React, { useState } from "react";
import Selectslide from "./selectSlide";
import Share from "./shareproject";
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetUserInfoQuery,
  useGetProjectInfoQuery,
  useAddCaseToProjectMutation,
} from "../../state/api/medicalApi";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../hooks/utility";
import { resetNewProject } from "../../state/reducers/newProjectReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../Loading/loading";
import _ from "lodash";
import SelectSlideHelper from "./selectSlideHelper";
import PreviewSlides from "./previewSlides";

const NewCase = ({
  isOpen,
  onClose,
  projectId,
  projectType,
  projectDescription,
  casesLength,
}) => {
  const { user } = useAuth0();
  const { isLoading, data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });

  const [selected, setSelected] = useState({});
  const [count, setCount] = useState(false);
  const [lastSelected, setLastSelected] = useState(null);
  const [slides, setSlides] = useState([]);

  const id = getUserId(user);
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserAuthenticated = useUserAuthentication();

  const [addCase] = useAddCaseToProjectMutation();

  const createCase = async () => {
    await addCase({
      subClaim: user?.sub,
      projectId: projectId,
      slides: slides[0],
      caseName: `Case${casesLength + 1}`,
      caseDescription: projectDescription,
    });
    onClose();
    setSelected({});
    setCount(false);
    setLastSelected(null);
    setSlides([]);
  };

  const handleClickOnSlide = (slide) => {
    const { slideName, awsImageBucketUrl } = slide;

    if (projectType === "singleSlide") {
      setSelected({ [slide._id]: { slideName, awsImageBucketUrl } });
      setCount(true);
    } else {
      if (_.keys(selected).length < 2) {
        if (_.keys(selected).length === 1) {
          setCount(true);
          setLastSelected(slide._id);
        }
        setSelected({
          ...selected,
          [slide._id]: { slideName, awsImageBucketUrl },
        });
      } else {
        setSelected({
          [lastSelected]: { ...selected[lastSelected] },
          [slide._id]: { slideName, awsImageBucketUrl },
        });
        setLastSelected(slide._id);
      }
    }
  };

  const handleSelectedSlides = () => {
    const projectSlides = [];

    if (projectType === "singleSlide") {
      _.values(selected).map((value) => {
        projectSlides.push(value);
      });
    } else {
      _.values(selected).map((value) => {
        projectSlides.push(value);
      });
    }
    setSlides([projectSlides]);
  };

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent maxH="683px" maxW="784px" background="#ECECEC">
        <ModalCloseButton mt={3.5} />
        <ModalBody p={0}>
          <Flex direction="column" p="40px 0px 20px 0px">
            <SelectSlideHelper
              slides={userInfo?.user.slides}
              handleClickOnSlide={handleClickOnSlide}
              handleSelectedSlides={handleSelectedSlides}
              selected={selected}
              count={count}
              ml={4}
            />
            <Button
              onClick={createCase}
              maxW="150px"
              bgColor="#00153F"
              borderRadius="0px"
              color="#fff"
              fontFamily="inter"
              m="10px 10px 10px 28px"
              disabled={!count}
            >
              ADD
            </Button>
            {slides.length > 0 ? (
              <PreviewSlides projectSlides={slides} />
            ) : null}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewCase;
