import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Text, Icon } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserProjectsQuery } from "../../state/api/medicalApi";
import moment from "moment";

function ClinicalStudy() {
  const { user } = useAuth0();
  const { data: projects, isLoading } = useGetUserProjectsQuery({
    subClaim: user?.sub,
  });
  return (
    <Box
      w="39.687vw"
      bgColor="#ECECEC"
      h="17.2962vh"
      boxShadow="0px 4px 4px rgba(21, 28, 37, 0.05)"
      left="17.604vw"
      top="12.5925vh"
      pos="absolute"
      overflow="hidden"
    >
      <Box width="100%" height="5.370vh" background="#E4E5E8">
        <Text
          fontSize="1.25vw"
          fontFamily="fira sans"
          fontWeight={600}
          marginStart="1.25vw"
          lineHeight="5.370vh"
          alignItems="center"
        >
          Clinical Study Information
        </Text>
      </Box>
      <Box px="1.025vw" mt="1.111vh" pb="1.25vw">
        <Table variant="unstyled">
          <Thead>
            <Tr border="1px solid #000">
              <Th
                fontSize="0.729vw"
                p="1.1vh 0.625vw"
                fontFamily="inter"
                textTransform="none"
                border="1px solid #000"
              >
                Project title:
              </Th>

              <Th
                fontSize="0.729vw"
                p="1.1vh 0.625vw"
                fontFamily="inter"
                textTransform="none"
              >
                Organization/PI:
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {projects?.projects.map((project) => {
              return (
                <Tr border="1px solid #000">
                  <Th
                    fontSize="0.729vw"
                    p="1.1vh 0.625vw"
                    fontFamily="inter"
                    textTransform="none"
                    border="1px solid #000"
                  >
                    {project?.name}
                  </Th>

                  <Th
                    fontSize="0.729vw"
                    p="1.1vh 0.625vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    <Icon as={AiOutlineInfoCircle} color="red" /> Confidential
                  </Th>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default ClinicalStudy;
