let _Environments = {
  production: {
    env: "production",
    USER_URL: "https://production-api.medai.prr.ai",
    LOGS: false,
    AUTH0_DOMAIN: "softsensor.us.auth0.com",
    AUTH0_CLIENT_ID: "87v5mDv8D1LR82cdVIqHwJN1XzmEQwyw",
    AUTH0_REDIRECT_URI: "dashboard",
    AUTH0_AUDIENCE: "aud://softsensor.prod",
  },
  staging: {
    env: "staging",
    USER_URL: "https://staging-api.medai.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-dev.us.auth0.com",
    AUTH0_CLIENT_ID: "OqCufd3WopR3RPlYBACOQse3XpTPar00",
    AUTH0_REDIRECT_URI: "dashboard",
    AUTH0_AUDIENCE: "aud://softsensor.dev",
  },
  development: {
    env: "development",
    USER_URL: "https://development-api.medai.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-dev.us.auth0.com",
    AUTH0_CLIENT_ID: "OqCufd3WopR3RPlYBACOQse3XpTPar00",
    AUTH0_REDIRECT_URI: "dashboard",
    AUTH0_AUDIENCE: "aud://softsensor.dev",
  },
  local: {
    env: "local",
    USER_URL: "https://development-api.medai.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-dev.us.auth0.com",
    AUTH0_CLIENT_ID: "OqCufd3WopR3RPlYBACOQse3XpTPar00",
    AUTH0_REDIRECT_URI: "dashboard",
    AUTH0_AUDIENCE: "aud://softsensor.dev",
  },
};

function getEnvironment() {
  var env = "development";
  if (window.location.href.includes("localhost")) {
    env = "local";
  } else if (window.location.href.includes("development")) {
    env = "development";
  } else if (window.location.href.includes("staging")) {
    env = "staging";
  } else {
    env = "production";
  }
  return _Environments[env];
}

var Environment = getEnvironment();
module.exports = Environment;
