import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  Box,
  Button,
  Input,
  HStack,
  VStack,
  IconButton,
  Checkbox,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { addQuestions } from "../../state/reducers/newProjectReducer";

function CustomQuestionnaire({ handleCustomQuestionnaire, questionState }) {
  const dispatch = useDispatch();

  const [questionText, setQuestionText] = useState("");
  const [choices, setChoices] = useState([""]);

  const handleFormChange = (index, event) => {
    let data = [...choices];
    data[index] = event.target.value;
    setChoices(data);
  };
  const addOption = () => {
    setChoices([...choices, ""]);
  };
  const Questions = [
    {
      questionText: questionText,
      questionType: questionState,
      choices: [...choices],
    },
  ];

  const closeQuestionnaireTab = () => {
    handleCustomQuestionnaire();
  };
  const submit = (e) => {
    e.preventDefault();
    dispatch(addQuestions(...Questions));
    closeQuestionnaireTab();
  };
  return (
    <>
      <Box
        w="728px"
        m="28px"
        bgColor="#CDCDCB"
        h="160px"
        overflow="auto"
        pb="10px"
      >
        <VStack alignItems="flex-start" ml="16px">
          <form onSubmit={submit}>
            <HStack mt="16px" justifyContent="space-between">
              <Box>
                <Input
                  name="question"
                  value={questionText}
                  fontFamily="inter"
                  required
                  onChange={(event) => setQuestionText(event.target.value)}
                  placeholder="Question"
                  bgColor="#F8F8F5"
                  width="500px"
                  size="md"
                  borderRadius="0px"
                />
                <IconButton
                  icon={<RiDeleteBin6Line size={18} />}
                  onClick={() => closeQuestionnaireTab()}
                  ml="24px"
                />
              </Box>
              <Button
                w="100px"
                type="submit"
                size="md"
                fontSize="14px"
                bgColor="#00153F"
                color="#fff"
                borderRadius="0px"
                fontFamily="inter"
                fontWeight="500"
              >
                Save
              </Button>
            </HStack>
            {choices.map((choice, index) => {
              return (
                <div key={index}>
                  <VStack
                    alignItems="flex-start"
                    justifyContent="center"
                    width="700px"
                    height="42px"
                    bgColor="#F8F8F5"
                    mt="5px"
                  >
                    <HStack h="100%" w="100%" key={index}>
                      <Checkbox ml="12px" name="checkbox"></Checkbox>
                      <Input
                        name="choiceText"
                        placeholder="option"
                        value={choice.choiceText}
                        onChange={(event) => handleFormChange(index, event)}
                        borderRadius="0px"
                        height="100%"
                        w="100%"
                        border="none"
                        outline="none"
                        _focus={{ border: "none" }}
                        fontFamily="inter"
                        required
                      />
                    </HStack>
                  </VStack>
                </div>
              );
            })}
            <HStack mt="8px">
              <Button
                onClick={() => addOption()}
                size="sm"
                variant="ghost"
                fontFamily="inter"
                fontsixe="14px"
                fontWeight="400"
              >
                +Add option
              </Button>
            </HStack>
          </form>
        </VStack>
      </Box>
    </>
  );
}
export default CustomQuestionnaire;
