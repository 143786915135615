import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserId } from "../../hooks/utility";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import { useHistory } from "react-router-dom";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import Loading from "../Loading/loading";

const PageNotFound = () => {
  const { user, logout } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const isUserAuthenticated = useUserAuthentication();
  const history = useHistory();

  const handleClick = () => {
    const id = getUserId(user);
    {
      userInfo?.user.userType === "reader"
        ? history.replace(`/${id}/dashboard/newcases`)
        : history.replace(`/${id}/dashboard/projects`);
    }
  };
  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <>
      <Button
        pos="absolute"
        right={15}
        top={15}
        onClick={() => logout({ returnTo: window.location.origin + "/login" })}
      >
        Log Out
      </Button>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        w="100vw"
        h="100vh"
      >
        <Text fontSize="40px">404</Text>
        <Text fontSize="24px">Page Not Found</Text>
        <Button onClick={handleClick} width="260px" mt="20px">
          Go Home
        </Button>
      </Flex>
    </>
  );
};

export default PageNotFound;
