import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  MenuItem,
  Icon,
  Spacer,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import Projectdetails from "./projectdetails";
import Selectslide from "./selectSlide";
import CreateQuestionnaire from "./createQuestionnaire";
import Share from "./shareproject";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DashboardMenu from "../Dashboard/menu";
import { resetNewProject } from "../../state/reducers/newProjectReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useCreateProjectMutation,
  useAddMultipleMembersToProjectByEmailMutation,
  useGetUserInfoQuery,
} from "../../state/api/medicalApi";
import Loading from "../Loading/loading";
import { getAccessToken, getUserId } from "../../hooks/utility";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import "../../styles/newproject.css";
import ProjectDetailsIcon from "../../images/new-project-images/projectDetails.svg";
import SelectSlideIcon from "../../images/new-project-images/SelectSlide.svg";
import QuestionnaireIcon from "../../images/new-project-images/questionnaireIcon.svg";
import ShareIcon from "../../images/new-project-images/ShareIcon.svg";

const Newproject = ({ onClose }) => {
  const [activeOption, setActiveOption] = useState("projectDetails");
  const { user } = useAuth0();
  const { projectDetails, members, questions } = useSelector(
    (state) => state.newProjectState
  );
  const isUserAuthenticated = useUserAuthentication();
  const id = getUserId(user);
  const history = useHistory();
  const { isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const [createProject] = useCreateProjectMutation();
  const [addMultipleMembersToProjectByEmail] =
    useAddMultipleMembersToProjectByEmailMutation();
  const dispatch = useDispatch();
  const handleReset = () => {
    dispatch(resetNewProject());
  };

  const [buttonStyleProjectDetails, setButtonStyleProjectDetails] =
    useState("selected_button");
  const [buttonBackgroundProjectDetails, setButtonBackgroundProjectDetails] =
    useState("#ECECEC");
  const [buttonStyleSelectSlide, setButtonStyleSelectSlide] =
    useState("unselected_button");
  const [buttonBackgroundSelectSlide, setButtonBackgroundSelectSlide] =
    useState("#ECECEC");
  const [buttonStyleQuestionnaire, setButtonStyleQuestionnaire] =
    useState("unselected_button");
  const [buttonBackgroundQuestionnaire, setButtonBackgroundQuestionnaire] =
    useState("#ECECEC");
  const [buttonStyleShare, setButtonStyleShare] = useState("unselected_button");
  const [buttonBackgroundShare, setButtonBackgroundShare] = useState("#ECECEC");
  const [buttonText, setButtonText] = useState("Save & Next");

  const handleCreateProject = async () => {
    const resp = await createProject({
      subClaim: user?.sub,
      uploadType: "multiUpload",
      questionnaire: {
        questions,
      },
      ...projectDetails,
    });
    await addMultipleMembersToProjectByEmail({
      subClaim: user?.sub,
      emails: members,
      projectId: resp.data._id,
    });
    dispatch(resetNewProject());
    history.push(`/${id}/dashboard/projects`);
    handleActiveOptionProjectDetails();
  };

  const handleActiveOptionProjectDetails = (e) => {
    setActiveOption("projectDetails");
    setButtonStyleProjectDetails("selected_button");
    setButtonStyleSelectSlide("unselected_button");
    setButtonStyleShare("unselected_button");
    setButtonStyleQuestionnaire("unselected_button");
    setButtonText("Save & Next");
  };
  const handleActiveOptionSelectSlide = (e) => {
    setActiveOption("selectSlide");
    setButtonStyleSelectSlide("selected_button");
    setButtonStyleShare("unselected_button");
    setButtonStyleQuestionnaire("unselected_button");
    setButtonStyleProjectDetails("unselected_button");
    setButtonText("Save & Next");
  };
  const handleActiveOptionQuestionnaire = (e) => {
    setActiveOption("questionnaire");
    setButtonStyleQuestionnaire("selected_button");
    setButtonStyleProjectDetails("unselected_button");
    setButtonStyleSelectSlide("unselected_button");
    setButtonStyleShare("unselected_button");
    setButtonText("Next");
  };
  const handleActiveOptionShare = (e) => {
    setActiveOption("share");
    setButtonStyleShare("selected_button");
    setButtonStyleProjectDetails("unselected_button");
    setButtonStyleSelectSlide("unselected_button");
    setButtonStyleQuestionnaire("unselected_button");
    setButtonText("Create");
  };

  const setNextButton = () => {
    if (activeOption === "selectSlide") {
      setButtonText("Share");
      handleActiveOptionQuestionnaire();
    } else if (activeOption === "questionnaire") {
      handleActiveOptionShare();
      setButtonText("Create");
    } else if (activeOption === "projectDetails") {
      handleActiveOptionSelectSlide();
      setButtonText("Next");
    } else {
      handleCreateProject();
      onClose();
    }
  };

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <>
      <Box fontFamily="roboto" background="#ECECEC">
        <Box
          width="784px"
          height="77px"
          background="#E4E5E8"
          marginBottom="20px"
        >
          <Button
            className={buttonStyleProjectDetails}
            name="projectDetails"
            onClick={(e) => handleActiveOptionProjectDetails(e)}
            background={buttonBackgroundProjectDetails}
            fontSize="18px"
            fontFamily="fira sans"
            fontWeight={600}
            width="172px"
            height="41px"
            borderRadius={0}
            border={
              activeOption === "projectDetails" ? "1px solid #00153F" : "none"
            }
            _focus={{ outline: "none" }}
            ml="28px"
            mt="18px"
          >
            <Image
              src={ProjectDetailsIcon}
              marginRight={2}
              width="20px"
              height="20px"
              marginTop={-1}
            />
            Project details
          </Button>
          <Button
            className={buttonStyleSelectSlide}
            name="selectSlide"
            onClick={(e) => handleActiveOptionSelectSlide(e)}
            background={buttonBackgroundSelectSlide}
            fontSize="18px"
            fontFamily="fira sans"
            fontWeight={600}
            width="172px"
            height="41px"
            borderRadius={0}
            border={
              activeOption === "selectSlide" ? "1px solid #00153F" : "none"
            }
            _focus={{ outline: "none" }}
            mt="18px"
          >
            <Image
              src={SelectSlideIcon}
              marginRight={2}
              width="20px"
              height="20px"
              marginTop={-1}
            />
            Select Slides
          </Button>
          <Button
            className={buttonStyleQuestionnaire}
            name="questionnaire"
            onClick={(e) => handleActiveOptionQuestionnaire(e)}
            background={buttonBackgroundQuestionnaire}
            fontFamily="fira sans"
            fontWeight={600}
            fontSize="18px"
            width="172px"
            height="41px"
            mt="18px"
            borderRadius={0}
            border={
              activeOption === "questionnaire" ? "1px solid #00153F" : "none"
            }
            _focus={{ outline: "none" }}
          >
            <Image
              src={QuestionnaireIcon}
              marginRight={2}
              width="20px"
              height="20px"
              marginTop={-1}
            />
            Questionnaire
          </Button>
          <Button
            className={buttonStyleShare}
            name="share"
            onClick={(e) => handleActiveOptionShare(e)}
            background={buttonBackgroundShare}
            fontWeight={600}
            fontFamily="fira sans"
            fontSize="18px"
            width="172px"
            height="41px"
            mt="18px"
            borderRadius={0}
            border={activeOption === "share" ? "1px solid #00153F" : "none"}
            _focus={{ outline: "none" }}
          >
            <Image
              src={ShareIcon}
              marginRight={2}
              width="20px"
              height="20px"
              marginTop={-1}
            />
            Share
          </Button>
        </Box>

        <Box>
          {activeOption === "projectDetails" && <Projectdetails />}
          {activeOption === "selectSlide" && <Selectslide />}
          {activeOption === "questionnaire" && <CreateQuestionnaire />}
          {activeOption === "share" && <Share />}
        </Box>
        <Box pl="28px" pb="18px" pt="28px">
          <Button
            width={150}
            fontFamily="fira sans"
            fontSize="18px"
            fontWeight="600"
            onClick={handleReset}
            border="1px solid #00153F"
            borderRadius={0}
            color="#00153F"
          >
            Reset
          </Button>
          <Button
            bg="#00153F"
            color="white"
            width={150}
            fontFamily="fira sans"
            fontSize="18px"
            fontWeight="600"
            ml={6}
            borderRadius={0}
            onClick={() => setNextButton()}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Newproject;
