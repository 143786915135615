import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Text,
  Select,
  Input,
  HStack,
  VStack,
  Icon,
  IconButton,
  Checkbox,
} from "@chakra-ui/react";

function TextboxQuesionnaire({ handleTextboxQuestionnaire, questionState }) {
  const [questions, setQuestions] = useState([
    { questionText: "", questionType: questionState, answer: "" },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...questions];
    data[index][event.target.name] = event.target.value;
    setQuestions(data);
  };

  const removeFields = () => {
    handleTextboxQuestionnaire();
  };
  const submit = (e) => {
    e.preventDefault();
    console.log(questions);
  };
  return (
    <>
      <Box w="728px" m="28px" bgColor="#CDCDCB" h="80px" overflow="auto">
        <VStack alignItems="flex-start" ml="16px">
          <form onSubmit={submit}>
            {questions.map((question, index) => {
              return (
                <div key={index}>
                  <HStack mt="16px" justifyContent="space-between">
                    <Box mr="28px">
                      <Input
                        name="questionText"
                        fontFamily="inter"
                        required
                        placeholder="Question"
                        bgColor="#F8F8F5"
                        width="500px"
                        size="md"
                        borderRadius="0px"
                        value={question.questionText}
                        onChange={(event) => handleFormChange(index, event)}
                      />
                      <IconButton
                        icon={<RiDeleteBin6Line size={18} />}
                        onClick={() => removeFields()}
                        ml="24px"
                      />
                    </Box>
                    <Button
                      w="100px"
                      type="submit"
                      size="md"
                      fontSize="14px"
                      bgColor="#00153F"
                      color="#fff"
                      borderRadius="0px"
                      fontFamily="inter"
                      fontWeight="500"
                      mt="5px"
                    >
                      {" "}
                      Save
                    </Button>
                  </HStack>
                </div>
              );
            })}
          </form>
        </VStack>
      </Box>
    </>
  );
}

export default TextboxQuesionnaire;
