import React from "react";
import { IconButton } from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportDocument from "./reportDocument";
import { GrDocumentDownload } from "react-icons/gr";

const CaseReport = ({ report, reportType, caseId, userId = null }) => {
  return (
    <PDFDownloadLink
      document={
        <ReportDocument
          report={report}
          reportType={reportType}
          caseId={caseId}
          userId={userId}
        />
      }
      fileName="caseReport.pdf"
    >
      <IconButton
        onClick={(event) => event.stopPropagation()}
        icon={<GrDocumentDownload />}
        colorScheme="#ececec"
        color="#000"
        variant="unstyled"
        _focus={{ outline: "none", border: "none" }}
        disabled={!report}
      />
    </PDFDownloadLink>
  );
};

export default CaseReport;
