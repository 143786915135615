import React from "react";
import { Flex, Text, Spacer } from "@chakra-ui/react";
import Search from "./search";
import LogoutButton from "../Authenticate/logout";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";

const Header = () => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  return (
    <Flex
      className="dashboard__header"
      bg="#E4E5E8"
      color="black"
      width="100vw"
      py={5}
      px={10}
      sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}
      alignItems="center"
      height={90}
    >
      <Text
        fontFamily="fira sans"
        fontWeight="500"
        fontSize="1.25vw"
        mt={"5px"}
      >
        {`Welcome Dr. ${userInfo?.user.firstName}`}
      </Text>
      <Search />
      <LogoutButton />
    </Flex>
  );
};

export default Header;
