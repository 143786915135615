import React, { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  useMediaQuery,
  Box,
} from "@chakra-ui/react";
import { RiBarChart2Line } from "react-icons/ri";
import { useGetReaderOverallStatsQuery } from "../../state/api/medicalApi";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/loading";
import _ from "lodash";
import StatsTable from "./statsTable";
import SelectDate from "./selectDate";

const ReaderOverallStats = ({ total, completed }) => {
  const { user } = useAuth0();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [ifScreenlessthan1536px] = useMediaQuery("(max-width:1536px)");
  const {
    isLoading,
    isFetching,
    data: casesStats,
    refetch,
  } = useGetReaderOverallStatsQuery(
    {
      subClaim: user?.sub,
      ...dateRange,
    }
    // { skip: !dateRange.startDate }
  );

  useEffect(() => {
    if (!dateRange.startDate) return;
    refetch();
  }, [dateRange]);

  return (
    <Box
      backgroundColor="#ECECEC"
      boxShadow="0px 4px 4px rgba(21, 28, 37, 0.05)"
      width="39.6875vw"
      height="17.2962vh"
      pos="absolute"
      left="59.375vw"
      top="12.5925vh"
    >
      <Box width="100%" height="5.370vh" background="#E4E5E8">
        <Text
          fontSize="1.25vw"
          fontFamily="fira sans"
          fontWeight={600}
          marginStart="1.25vw"
          lineHeight="5.370vh"
          alignItems="center"
        >
          Clinical study statistics
        </Text>
      </Box>
      {isFetching ? (
        <Loading />
      ) : casesStats ? (
        <StatsTable
          casesStats={casesStats}
          total={total}
          completed={completed}
        />
      ) : (
        <Text>error</Text>
      )}
    </Box>
  );
};

export default ReaderOverallStats;
