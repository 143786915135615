import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./state/store";
import Environment from "./environment";

ReactDOM.render(
  <Auth0Provider
    domain={Environment.AUTH0_DOMAIN}
    clientId={Environment.AUTH0_CLIENT_ID}
    redirectUri={window.location.origin + "/" + Environment.AUTH0_REDIRECT_URI}
    audience={Environment.AUTH0_AUDIENCE}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
