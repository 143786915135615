import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import Loading from "../Loading/loading";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import { getUserId } from "../../hooks/utility";

const DashboardRedirect = () => {
  const { user } = useAuth0();
  const isUserAuthenticated = useUserAuthentication();
  const history = useHistory();
  const { token } = useSelector((state) => state.authState);
  const { data, error, refetch } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !token }
  );

  useEffect(() => {
    if (!data && !error) return;
    const id = getUserId(user);
    if (error && error.status === 404) history.push(`/${id}/registrationForm`);
    if (data) {
      data?.user?.userType === "reader"
        ? history.replace(`/${id}/dashboard/newcases`)
        : history.replace(`/${id}/dashboard/projects`);
    }
    refetch();
  }, [data, error]);

  return <Loading />;
};

export default DashboardRedirect;
