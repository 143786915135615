import React from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Select,
  Text,
  VStack,
  HStack,
  IconButton,
  Popover,
  Spacer,
  Button,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import SelectSlideModal from "./selectSlideModal";
import Projectdetails from "./projectdetails";
import UploadIcon from "../../images/new-project-images/UploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";

const SelectSlideHelper = ({
  handleClickOnSlide,
  handleSelectedSlides,
  selected,
  count,
  disabled = false,
  ...restProps
}) => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const { projectDetails, uploadedFile, slides } = useSelector(
  //     (state) => state.newProjectStte
  //   );
  return (
    <FormControl {...restProps}>
      <FormLabel color="#000" fontSize="14px">
        Select Slide
      </FormLabel>
      <Button
        w="728px"
        h="200px"
        placeholder="Select Slides"
        variant="filled"
        border="1px dashed rgba(21, 28, 37, 1)"
        bgColor="#ECECEC"
        color="#000"
        disabled={disabled}
        onClick={onOpen}
        fontSize="14px"
        fontWeight="400"
        fontFamily="inter"
        borderRadius="0px"
      >
        <VStack>
          <Image src={UploadIcon}></Image>
          <Text
            fontFamily="fira sans"
            fontSize="16px"
            border="1px solid #000"
            padding="8px"
          >
            Select Files
          </Text>
          <Text fontFamily="inter" color="rgba(0, 0, 0, 0.5)">
            Select WSI Images from your cloud
          </Text>
        </VStack>
      </Button>
      <SelectSlideModal
        isOpen={isOpen}
        onClose={onClose}
        slides={userInfo?.user.slides}
        handleClickOnSlide={handleClickOnSlide}
        handleSelectedSlides={handleSelectedSlides}
        selected={selected}
        count={count}
      />
    </FormControl>
  );
};

export default SelectSlideHelper;
