import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  VStack,
  Text,
  HStack,
  Button,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useGetProjectInfoQuery } from "../../state/api/medicalApi";
import { isCaseViewable } from "../../hooks/utility";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import "../../styles/viewer.css";

const ChangeCase = ({ closeToggle }) => {
  const { user } = useAuth0();
  const history = useHistory();
  const { projectId, caseId } = history.location?.state;
  const { data: project, isLoading } = useGetProjectInfoQuery({
    subClaim: user?.sub,
    projectId: projectId,
  });
  const currentCaseIndex = project?.cases.findIndex(
    (projectCase) => projectCase._id === caseId
  );
  const id = user?.sub.substring(user?.sub.indexOf("|") + 1);
  const [closeButton, setCloseButton] = useState(true);
  const handleCloseButtonClick = () => {
    setCloseButton(false);
    closeToggle(false);
  };

  const handleChangeClick = (index) => {
    history.replace({
      pathname: `/${id}/project/${projectId}/slideRedirect`,
      state: {
        caseId: project?.cases[index]._id,
        projectId: projectId,
        slides: project?.cases[index].slides,
        slideType: project?.slideType,
        questionnaire: project?.questionnaire,
      },
    });
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderX="2px solid #E4E5E8"
        height="18px"
        minW="140px"
      >
        <HStack mx="28px">
          <Tooltip
            label="Previous Slide"
            placement="bottom"
            openDelay={0}
            bg="#E4E5E8"
            color="rgba(89, 89, 89, 1)"
            fontSize="14px"
            fontFamily="inter"
            hasArrow
            borderRadius="0px"
            size="20px"
          >
            <IconButton
              icon={<MdOutlineKeyboardArrowLeft color="#151C25" />}
              color="#fff"
              variant="unstyled"
              cursor="pointer"
              minW={0}
              _focus={{ background: "none" }}
              disabled={
                currentCaseIndex - 1 < 0 ||
                !isCaseViewable(
                  project?.type,
                  project?.cases[currentCaseIndex - 1].slides.length
                )
              }
              onClick={() => handleChangeClick(currentCaseIndex - 1)}
            />
          </Tooltip>

          <Text mr="24px">
            {project?.cases[currentCaseIndex].name}
          </Text>
          <Tooltip
            label="Next Slide"
            placement="bottom"
            openDelay={0}
            bg="#E4E5E8"
            color="rgba(89, 89, 89, 1)"
            fontSize="14px"
            fontFamily="inter"
            hasArrow
            borderRadius="0px"
            size="20px"
          >
            <IconButton
              icon={<MdOutlineKeyboardArrowRight color="#151C25" />}
              variant="unstyled"
              color="#fff"
              cursor="pointer"
              minW={0}
              _focus={{ background: "none", border: "none" }}
              disabled={
                currentCaseIndex + 1 === project?.cases.length ||
                !isCaseViewable(
                  project?.type,
                  project?.cases[currentCaseIndex + 1].slides.length
                )
              }
              onClick={() => handleChangeClick(currentCaseIndex + 1)}
            />
          </Tooltip>
        </HStack>
      </Flex>
    </>
  );
};

export default ChangeCase;
