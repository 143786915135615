import React, { useState, useEffect } from "react";
import { Link as RouteLink, useHistory, useLocation } from "react-router-dom";
import {
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useMediaQuery,
  Box,
  VStack,
} from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import { getUserId } from "../../hooks/utility";
import Popup from "../Popup/popup";
import recentProject from "../../images/new-project-images/recentProject.svg";
import newProjectIcon from "../../images/new-project-images/projectNew.svg";
import projectNewIcon from "../../images/new-project-images/newProject.svg";
import helpIcon from "../../images/new-project-images/help.svg";
import Newproject from "../Newproject/newproject";

const DashboardMenu = () => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const userId = getUserId(user);
  const [popup, setPopup] = useState(false);
  const handlePopup = () => {
    setPopup(!popup);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ifScreenlessthan1536] = useMediaQuery("(max-width:1536px)");
  return (
    <>
      {/* <Menu
        defaultIsOpen={true}
        closeOnBlur={false}
        closeOnSelect={false}
        autoSelect={false}
      >
        <MenuList
          bg="#E4E5E8"
          height="100vh"
          className="dashboard__menu"
          borderRadius="0px"
          border="none"
          color="black"
          position="fixed"
          boxShadow="1px 0px 1px rgba(89, 89, 89, 0.15)"
        >
          <Flex>
            <Image
              borderRadius="full"
              boxSize="50px"
              mx={3}
              marginTop="1em"
              src={user?.picture}
              alt="User"
            />
            <Flex marginTop="1em" direction="column" width="100%">
              <Text
                color="black"
                fontFamily="fira sans"
                fontWeight="600"
                fontSize={ifScreenlessthan1536 ? "16px" : "18px"}
              >
                {"Dr. " +
                  userInfo?.user.firstName +
                  " " +
                  userInfo?.user.lastName}
              </Text>
              <Link
                className="menu__managelink"
                color="black"
                marginTop="6px"
                fontSize={12}
                textDecoration="underline"
                fontWeight="semibold"
              >
                Manage your account
              </Link>
            </Flex>
          </Flex>
          <MenuGroup>
            <MenuDivider
              marginTop="2em"
              borderColor="rgba(140, 140, 140, 0.4)"
              border="2px"
            />
            <MenuItem
              _hover={{ bg: "#F8F8F5" }}
              name="recent"
              className="dashboard__menu__list"
              onClick={handlePopup}
              alignItems="center"
              marginTop={6}
            >
              <Image
                src={recentProject}
                marginRight={2}
                width="20px"
                height="20px"
              />
              <Text fontWeight="semibold" marginLeft="1px">
                Recent Projects
              </Text>
            </MenuItem>
            <Link
              as={RouteLink}
              to={`/${userId}/dashboard/projects`}
              _hover={{ textDecoration: "none" }}
            >
              <MenuItem
                _hover={{ bg: "#F8F8F5" }}
                name="projects"
                className="dashboard__menu__list"
                alignItems="center"
                marginTop={3}
              >
                <Image
                  src={newProjectIcon}
                  marginRight={2}
                  width="20px"
                  height="20px"
                />
                <Text fontWeight="semibold">Projects</Text>
              </MenuItem>
            </Link>
            <MenuItem
              _hover={{ bg: "#F8F8F5" }}
              name="newProject"
              className="dashboard__menu__list"
              marginTop={2}
              paddingLeft="15px"
              onClick={onOpen}
            >
              <Image
                src={projectNewIcon}
                marginRight={2}
                width="20px"
                height="20px"
              />
              <Text fontWeight="semibold">New Project</Text>
              <Spacer />
            </MenuItem>

            <MenuDivider
              marginTop="1em"
              borderColor="rgba(140, 140, 140, 0.4)"
              border="2px"
            />
            <MenuDivider m={2} borderColor="#E4E5E8" />

            <MenuItem
              _hover={{ bg: "#F8F8F5" }}
              className="dashboard__menu__list"
              onClick={handlePopup}
              marginTop={3}
            >
              <Image
                src={helpIcon}
                marginRight={2}
                width="20px"
                height="20px"
              />
              <Text fontWeight="semibold">Help</Text>
            </MenuItem>
          </MenuGroup>
        </MenuList>
        <Popup
          handlePopup={() => {
            handlePopup();
          }}
          popup={popup}
        />
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
          <ModalOverlay />
          <ModalContent maxH="683px" maxW="784px" background="#ECECEC">
            <ModalCloseButton mt={3.5} />
            <ModalBody p={0}>
              <Newproject onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Menu> */}

      <Box bgColor="#E4E5E8" w="15.885vw" h="100vh">
        <Flex direction="column" py="2.170vh">
          <Flex borderBottom="2px solid rgba(140, 140, 140, 0.4)" pb="4.074vh">
            <Image
              borderRadius="full"
              boxSize="2.708vw"
              mx="1vw"
              src={user?.picture}
              alt="User"
            />
            <Flex direction="column">
              <Text
                color="black"
                fontFamily="fira sans"
                fontWeight="600"
                fontSize="1.0416vw"
              >
                {"Dr. " +
                  userInfo?.user.firstName +
                  " " +
                  userInfo?.user.lastName}
              </Text>

              <Text
                color="black"
                marginTop="0.3125vh"
                fontFamily="inter"
                fontSize="0.625vw"
              >
                {userInfo?.user.emailAddress}
              </Text>
            </Flex>
          </Flex>
          <Link
            as={RouteLink}
            to={`/${userId}/dashboard/projects`}
            _hover={{ textDecoration: "none" }}
          >
            <Flex
              direction="row"
              _hover={{ bg: "#F8F8F5" }}
              alignItems="center"
              _active={{ bgColor: "#fff" }}
              _selected={{ bgColor: "#fff" }}
              h="4.814vh"
              my="1.666vh"
              pl="0.937vw"
              mb={0}
            >
              <Image
                src={newProjectIcon}
                marginRight="0.584vw"
                width="0.69vw"
                height="1.956vh"
              />
              <Text
                fontWeight="semibold"
                fontFamily="inter"
                fontSize="0.729vw"
                ml="0.21vw"
              >
                Clinical Study
              </Text>
            </Flex>
          </Link>
          <Flex
            direction="row"
            _hover={{ bg: "#F8F8F5" }}
            alignItems="center"
            _active={{ bgColor: "#fff" }}
            _selected={{ bgColor: "#fff" }}
            h="4.814vh"
            my="1.666vh"
            pl="0.937vw"
            mt={0}
            onClick={onOpen}
            cursor="pointer"
          >
            <Image
              src={projectNewIcon}
              marginRight="0.584vw"
              width="0.9vw"
              height="1.956vh"
            />
            <Text fontWeight="semibold" fontFamily="inter" fontSize="0.729vw">
              Create New Study
            </Text>
          </Flex>
          <Flex
            direction="row"
            _hover={{ bg: "#F8F8F5" }}
            alignItems="center"
            _active={{ bgColor: "#fff" }}
            _selected={{ bgColor: "#fff" }}
            pl="0.937vw"
            h="4.814vh"
            borderTop="2px solid rgba(140, 140, 140, 0.4)"
            onClick={handlePopup}
          >
            <Image
              src={helpIcon}
              marginRight="0.584vw"
              width="0.69vw"
              height="1.956vh"
            />
            <Text fontWeight="semibold" fontFamily="inter" fontSize="0.729vw">
              Help
            </Text>
          </Flex>
        </Flex>
        <Popup
          handlePopup={() => {
            handlePopup();
          }}
          popup={popup}
        />
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
          <ModalOverlay />
          <ModalContent maxH="683px" maxW="784px" background="#ECECEC">
            <ModalCloseButton mt={3.5} />
            <ModalBody p={0}>
              <Newproject onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default DashboardMenu;
