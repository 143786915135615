import moment from "moment";
import _ from "lodash";

// transform cases response to stats for different time ranges
export const transformToCaseStats = (response, meta) => {
  // stats for table
  const stats = {
    assigned: 0,
    pending: 0,
    completed: 0,
  };

  // stats for different time ranges
  const timeSeparated = {
    "12am to 6am": { ...stats },
    "6am to 12pm": { ...stats },
    "12pm to 6pm": { ...stats },
    "6pm to 12am": { ...stats },
  };

  // compute and separate stats for different time ranges
  response &&
    response.forEach((c) => {
      const caseTime = moment(c.createdAt).format("HH:MM:SS");
      let timeRange = "";

      if ("00:00:00" <= caseTime && caseTime < "06:00:00")
        timeRange = "12am to 6am";
      else if ("06:00:00" <= caseTime && caseTime < "12:00:00")
        timeRange = "12am to 6am";
      else if ("12:00:00" <= caseTime && caseTime < "18:00:00")
        timeRange = "12pm to 6pm";
      else if ("18:00:00" <= caseTime && caseTime < "24:00:00")
        timeRange = "6pm to 12am";

      timeSeparated[timeRange].assigned++;
      if (c.status === "completed") timeSeparated[timeRange].completed++;
      else timeSeparated[timeRange].pending++;
    });

  // compute total stats
  const total = _.values(timeSeparated).reduce(
    (total, stat) => ({
      assigned: total.assigned + stat.assigned,
      pending: total.pending + stat.pending,
      completed: total.completed + stat.completed,
    }),
    { ...stats }
  );

  return { timeSeparated, total };
};

export const transformQuestionnaireResponse = (response = [], meta) => {
  const qnaResponses = {};
  response.forEach((question) => {
    const { questionId, choiceId, choiceText } = question;
    qnaResponses[questionId] = { choiceId, choiceText };
  });
  return qnaResponses;
};

export const transformPIProjectProgress = (response, meta) => {
  const caseProgress = {};
  response?.results.forEach((c) => {
    const completedUser = [];
    c?.users?.forEach((user) => {
      if (user?.caseStatus === "completed") completedUser.push(user?._id);
    });
    caseProgress[c?.caseId] = completedUser;
  });
  return caseProgress;
};
