import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { BiTime } from "react-icons/bi";
import { BsList } from "react-icons/bs";
import { IoGridOutline } from "react-icons/io5";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProjectLink from "../Project/projectLink";
import ProgressBar from "@ramonak/react-progress-bar";
import { useGetUserInfoQuery } from "../../state/api/medicalApi";
import moment from "moment";
import DashboardMenu from "../Dashboard/menu";
import Header from "../Dashboard/header";
import { useDispatch } from "react-redux";
import { getAccessToken } from "../../hooks/utility";
import Loading from "../Loading/loading";
import useUserAuthentication from "../../hooks/useUserAuthentication";

const Recent = () => {
  const { user } = useAuth0();
  const { isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const isUserAuthenticated = useUserAuthentication();

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <>
      <DashboardMenu />
      <Box
        marginLeft="14em"
        height="100vh"
        direction="column"
        backgroundColor="#eeeeee"
      >
        <Header />
      </Box>
    </>
  );
};

export default Recent;
