import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  VStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Spinner,
} from "@chakra-ui/react";
import DashboardMenu from "../Dashboard/menu";
import Search from "../Dashboard/search";
import {
  MdOutlineKeyboardArrowRight,
  MdKeyboardArrowDown,
} from "react-icons/md";
import Popup from "../Popup/popup";
import { useDisclosure } from "@chakra-ui/hooks";
import { AiOutlineProject } from "react-icons/ai";
import { BsList, BsCircleFill } from "react-icons/bs";
import { IoGridOutline, IoAdd } from "react-icons/io5";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Header from "../Dashboard/header";
import TasksAssigned from "./tasksAssigned";
import TeamInfo from "./teamInfo";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetPIProjectProgressQuery,
  useGetProjectInfoQuery,
  useUpdateLastViewedMutation,
} from "../../state/api/medicalApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getAccessToken } from "../../hooks/utility";
import Loading from "../Loading/loading";
import { useDispatch } from "react-redux";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import "../../styles/dashboard.css";
import { useMediaQuery } from "@chakra-ui/react";
import ProjectReport from "../Report/projectReport";
import NewCase from "../Newproject/newCase";
import ReaderHeader from "../Readerscreen/readerHeader";

const Project = ({ displayAvatarColumn }) => {
  const { user } = useAuth0();
  const location = useLocation();
  const { data: project, isLoading } = useGetProjectInfoQuery({
    subClaim: user?.sub,
    projectId: location?.state?.projectId,
  });
  const { data: projectProgress } = useGetPIProjectProgressQuery({
    subClaim: user?.sub,
    projectId: location?.state?.projectId,
  });
  const [updateLastViewed] = useUpdateLastViewedMutation();
  const isUserAuthenticated = useUserAuthentication();
  const [ifScreenlessthan1536px] = useMediaQuery("(max-width:1536px)");
  useEffect(() => {
    if (!project) return;
    return () => {
      updateLastViewed({ subClaim: user?.sub, projectId: project._id });
    };
  }, [project]);

  const [popup, setPopup] = useState(false);
  const handlePopup = () => {
    setPopup(!popup);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useState("");

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <>
      <Flex>
        <DashboardMenu />
        <Flex
          // marginLeft="14em"
          width="100%"
          height="100vh"
          direction="column"
          backgroundColor="#fff"
        >
          <ReaderHeader setQuery={setQuery} />
          {isLoading ? (
            <Flex justify="center" align="center" h="100vh" mt="20px">
              <Spinner
                color="#3965C5"
                size="xl"
                thickness="4px"
                speed="0.65s"
              />
            </Flex>
          ) : (
            <Flex
              direction="row"
              left="15.55208vw"
              top="11.59259vh"
              pos="absolute"
              minW="1024px"
              mr="1.041vw"
              boxShadow="0px 4px 4px rgba(21, 28, 37, 0.05)"
            >
              <Flex direction="column">
                <HStack
                  bgColor="#E4E5E8"
                  color="#151C25"
                  h="5.77vh"
                  pl="1.458vw"
                  pr="1.0937vw"
                >
                  <Text
                    fontSize="1.25vw"
                    fontWeight="600"
                    fontFamily="fira sans"
                    // my={2}
                    marginBottom="0.93vh"
                    marginTop="1.111vh"
                  >
                    {project?.name}
                  </Text>
                  <Spacer />
                  <Button
                    onClick={onOpen}
                    size="xs"
                    // maxW="150px"
                    bgColor="#E4E5E8"
                    border=" 1px solid #00153F"
                    borderRadius="0"
                    color="#00153F"
                    fontSize="0.729vw"
                    fontFamily="inter"
                    p="0.78vw 1.458vw"
                  >
                    + Add Cases
                  </Button>
                  {/* <Text
                    pl="12px"
                    fontSize="14px"
                    fontFamily="inter"
                    fontWeight="600"
                  >
                    {`Created by Dr. ${project?.owner.firstName} ${project?.owner.lastName}`}
                  </Text>

                  <Text
                    fontSize="14px"
                    fontFamily="inter"
                    pr="40px"
                    fontWeight="600"
                  >
                    {" • " + moment(project?.createdAt).format("DD MMM, YYYY")}
                  </Text>
                  <Spacer />
                  {project?.owner?.subClaim === user?.sub ? (
                    <ProjectReport
                      projectId={project._id}
                      reportType={project?.type}
                    />
                  ) : null} */}
                </HStack>
                <Flex bgColor="#ECECEC" direction="column">
                  <TasksAssigned
                    owner={project?.owner}
                    members={project?.members}
                    progress={projectProgress}
                    tasks={project?.cases}
                    projectType={project?.type}
                    projectId={project._id}
                    questionnaire={project?.questionnaire}
                    displayAvatarColumn={displayAvatarColumn}
                    slideType={project?.slideType}
                    query={query}
                  />
                </Flex>
              </Flex>
              {/* <Flex direction="column" marginRight="20px" mt="20px">
                <TeamInfo
                  members={project?.members}
                  projectOwner={project?.owner}
                  projectProgress={project?.projectProgress}
                  totalTasks={project?.cases.length}
                />
              </Flex> */}
              {/* <Popup
                handlePopup={() => {
                  handlePopup();
                }}
                popup={popup}
              /> */}
            </Flex>
          )}
        </Flex>
      </Flex>
      <NewCase
        isOpen={isOpen}
        onClose={onClose}
        projectId={project?._id}
        projectType={project?.type}
        projectDescription={project?.description}
        casesLength={project?.cases.length}
      />
    </>
  );
};

export default Project;
