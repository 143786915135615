/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  HStack,
  Link,
  Spacer,
  Text,
  Image,
  Avatar,
} from "@chakra-ui/react";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useGetPIUserProjectsQuery } from "../../state/api/medicalApi";
import "../../styles/dashboard.css";
import Loading from "../Loading/loading";
import projectIcon from "../../images/new-project-images/projectNew.svg";
import { Link as RouteLink } from "react-router-dom";
import nonCompletedProjectIcon from "../../images/readerScreen/notCompletedStatus.svg";
import completedProjectIcon from "../../images/readerScreen/completedStatus.svg";
import ProjectReport from "../Report/projectReport";
import { getUserId } from "../../hooks/utility";

const Projects = ({ query }) => {
  const { user } = useAuth0();
  const { data: projects, isLoading } = useGetPIUserProjectsQuery({
    subClaim: user?.sub,
  });

  const id = getUserId(user);

  return (
    <Box
      backgroundColor="#ECECEC"
      borderRadius="5px"
      boxShadow="0px 4px 4px rgba(21, 28, 37, 0.05)"
      overflow="auto"
      width="83.51041vw"
      height="85.14814vh"
      pos="absolute"
      top="11.59259vh"
      left="15.55208vw"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Flex background="#E4E5E8" width="100%">
            <Text
              fontFamily="fira sans"
              color="#1F1C25"
              marginLeft="2.3vw"
              marginBottom="0.93vh"
              marginTop="1.111vh"
              fontWeight="semibold"
              fontSize="1.25vw"
            >
              Clinical Study
            </Text>
          </Flex>
          <Box padding="18px" minW="700px" overflow="auto">
            <HStack color="#151C25" width="100%" pl="1.458vw" mb="10px">
              <Text
                fontSize="0.7291vw"
                fontFamily="inter"
                fontWeight="600"
                textTransform="none"
                minW="23%"
              >
                Clinical Study Name
              </Text>
              <Text
                fontSize="0.7291vw"
                fontFamily="inter"
                fontWeight="600"
                textTransform="none"
                minW="13%"
              >
                Organization
              </Text>
              <Text
                fontSize="0.7291vw"
                fontFamily="inter"
                fontWeight="600"
                textTransform="none"
                minW="13%"
              >
                Study Type
              </Text>
              <Text
                fontSize="0.7291vw"
                fontFamily="inter"
                fontWeight="600"
                textTransform="none"
                minW="13%"
              >
                Reader
              </Text>

              <Text
                fontSize="0.7291vw"
                fontFamily="inter"
                fontWeight="600"
                textTransform="none"
                minW="23%"
              >
                Progress
              </Text>
              <Text
                fontSize="0.7291vw"
                fontFamily="inter"
                fontWeight="600"
                textTransform="none"
                minW="10%"
              >
                Compiled data
              </Text>
            </HStack>

            {projects?.results
              .filter((project) => {
                let val = false;
                project?.projectDetails?.members.map((member) => {
                  member.firstName.toLowerCase().includes(query.toLowerCase())
                    ? (val = val | true)
                    : (val = val | false);
                });

                if (query === "") {
                  //if query is empty
                  return project;
                } else if (
                  project?.projectDetails?.name
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                  val
                ) {
                  //returns filtered array
                  return project;
                }
              })
              .map((project) => {
                return (
                  <HStack
                    borderBottom="1px solid #000"
                    _hover={{ background: "#F8F8F5", transition: "0.4s" }}
                    color="#151C25"
                    key={project?.projectDetails?._id}
                    w="100%"
                    alignItems="center"
                    alignContent="center"
                    pl="1.458vw"
                  >
                    <Link
                      as={RouteLink}
                      to={{
                        pathname: `/${id}/project/${project?.projectDetails?._id}`,
                        state: { projectId: project?.projectDetails?._id },
                      }}
                      width="100%"
                      style={{ textDecoration: "none" }}
                      _focus={{ border: "none" }}
                    >
                      <HStack w="100%" height="7.5vh" alignItems="center">
                        <Flex
                          fontWeight="600"
                          fontFamily="fira sans"
                          fontSize="0.95vw"
                          alignItems="center"
                          w="23%"
                        >
                          <Image
                            src={projectIcon}
                            width="1vw"
                            height="1.9vh"
                            mr="0.520vw"
                          />
                          <Text>{project?.projectDetails?.name}</Text>
                        </Flex>
                        <Text
                          fontFamily="inter"
                          fontSize="0.7291vw"
                          w="13%"
                          fontWeight={600}
                        >
                          Organization Name
                        </Text>
                        <Text
                          fontSize="0.7291vw"
                          w="13%"
                          fontFamily="inter"
                          fontWeight={600}
                        >
                          {project?.projectDetails?.type === "singleSlide"
                            ? "Single-Slide"
                            : "Multi-Slide"}
                        </Text>
                        <Text
                          fontSize="0.7291vw"
                          w="13%"
                          fontFamily="inter"
                          fontWeight={600}
                        >
                          {project?.projectDetails?.members.map((member) => {
                            return (
                              <Avatar
                                key={member._id}
                                name={`${member.firstName} ${member.lastName}`}
                                size="sm"
                                // border color should be changed with respect to status of project

                                mr="0.5vw"
                                backgroundColor="#fff"
                                color="#000"
                              />
                            );
                          })}
                        </Text>

                        <Flex w="23%" alignContent="center" alignItems="center">
                          <Flex
                            h="4.25vh"
                            // minW="150px"
                            w="12.2vw"
                            direction="column"
                            bgColor="#D8D8D6"
                            fontSize="0.83333vw"
                            fontFamily="inter"
                            pl="1.041vw"
                            justifyContent="center"
                            fontWeight={600}
                            pr="0.5208vw"
                          >
                            <Flex pr="0.5208vw" alignItems="center">
                              <Text
                                fontWeight="600"
                                fontFamily="fira sans"
                                fontSize="0.8333vw"
                                color={
                                  project.completed ===
                                  project?.projectDetails?.cases.length *
                                    project?.projectDetails?.members.length
                                    ? "#05A787"
                                    : project.completed >
                                      project?.projectDetails?.cases.length *
                                        project?.projectDetails?.members
                                          .length *
                                        0.25
                                    ? "#FF9900"
                                    : "#B00020"
                                }
                              >
                                {project.completed ?? "0"}
                              </Text>
                              <Text
                                fontWeight="600"
                                fontFamily="inter"
                                fontSize="0.7291vw"
                              >
                                {" "}
                                /
                                {project?.projectDetails?.cases.length *
                                  project?.projectDetails?.members.length +
                                  " Completed"}
                              </Text>
                            </Flex>
                            <ProgressBar
                              width="10.41666vw"
                              height="3px"
                              completed={
                                project?.completed ? project.completed : 0
                              }
                              maxCompleted={
                                project?.projectDetails?.cases.length *
                                project?.projectDetails?.members.length
                              }
                              baseBgColor="rgba(140, 140, 140, 0.5)"
                              bgColor="#595959"
                              isLabelVisible={false}
                            />
                          </Flex>
                        </Flex>
                        <Text
                          fontFamily="inter"
                          fontSize="0.7291vw"
                          w="10%"
                          fontWeight={600}
                        >
                          {project.completed ===
                          project?.projectDetails?.cases.length *
                            project?.projectDetails?.members.length ? (
                            <HStack>
                              <Image
                                src={completedProjectIcon}
                                width="1vw"
                                height="1.5vh"
                              />
                              <Text
                                fontSize="0.7291vw"
                                fontFamily="inter"
                                textTransform="none"
                                cursor="pointer"
                              >
                                View Data
                              </Text>
                              <ProjectReport
                                projectId={project?.projectDetails?._id}
                                reportType={project?.projectDetails?.type}
                              />
                            </HStack>
                          ) : (
                            <HStack>
                              <Image
                                src={nonCompletedProjectIcon}
                                width="1vw"
                                height="1.5vh"
                              />
                              <Text
                                fontSize="0.7291vw"
                                fontFamily="inter"
                                textTransform="none"
                                cursor="pointer"
                              >
                                View data
                              </Text>
                              <ProjectReport
                                projectId={project?.projectDetails?._id}
                                reportType={project?.projectDetails?.type}
                              />
                            </HStack>
                          )}
                        </Text>
                      </HStack>
                    </Link>
                  </HStack>
                );
              })}
          </Box>
        </>
      )}
      <Box background="#ECECEC" width="100%" height="10px"></Box>
    </Box>
  );
};

export default Projects;
