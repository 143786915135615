import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Image,
  Text,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import PreviewSlides from "./previewSlides";
import { getSlideUrl } from "../../hooks/utility";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { addSlides, updateCases } from "../../state/reducers/newProjectReducer";
import NewCase from "./newCase";

const SelectSlideModal = ({
  isOpen,
  onClose,
  slides,
  handleClickOnSlide,
  handleSelectedSlides,
  selected,
  count,
}) => {
  const dispatch = useDispatch();

  const handleDone = () => {
    handleSelectedSlides();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalHeader fontFamily="roboto" fontWeight="400" fontSize="18px">
            Select Slides
          </ModalHeader>
          <ModalCloseButton color="#000" border="1px solid #000" />
          <ModalBody>
            {slides?.length > 0 ? (
              <SimpleGrid
                minChildWidth="100px"
                spacing={2}
                p={2}
                borderRadius="5px"
                bgColor="rgba(236, 236, 236, 1)"
                maxW="100%"
              >
                {slides?.map((slide) => {
                  const slideUrl = getSlideUrl(slide.awsImageBucketUrl);
                  return (
                    <VStack
                      key={slide._id}
                      spacing={0}
                      onClick={() => handleClickOnSlide(slide)}
                      cursor="pointer"
                    >
                      <Image
                        w="100px"
                        h="80px"
                        objectFit="cover"
                        borderRadius="5px"
                        src={slideUrl}
                        border={
                          _.has(selected, slide._id) && selected[slide._id]
                            ? "2px solid #68D761"
                            : ""
                        }
                      />
                      <Text color="#000">{slide.slideName}</Text>
                    </VStack>
                  );
                })}
              </SimpleGrid>
            ) : (
              <Text>You have no slides</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="#fff"
              color="#00153f"
              border="1px solid #00153f"
              mr={3}
              onClick={onClose}
              borderRadius={0}
            >
              Close
            </Button>
            <Button
              bgColor="#00153f"
              color="#fff"
              borderRadius={0}
              disabled={!count}
              onClick={handleDone}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectSlideModal;
