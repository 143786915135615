import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Link,
  useMediaQuery,
  HStack,
  Flex,
  Button,
  Icon,
  Image,
  Avatar,
  VStack,
} from "@chakra-ui/react";
import { isCaseViewable } from "../../hooks/utility";
import { Link as RouteLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetUserInfoQuery,
  useGetProjectInfoQuery,
  useGetProjectReportQuery,
} from "../../state/api/medicalApi";
import _ from "lodash";
import newCasesIcon from "../../images/readerScreen/newCasesIcon.svg";
import nonCompletedProjectIcon from "../../images/readerScreen/notCompletedStatus.svg";
import completedProjectIcon from "../../images/readerScreen/completedStatus.svg";
import CaseReport from "../Report/caseReport";

const DisplayCases = ({
  uploadedAt,
  projectName,
  tasks,
  projectType,
  slideType,
  projectInfo,
  projectId,
  questionnaire,
  input,
}) => {
  const { user } = useAuth0();
  const [ifScreenlessthan1536px] = useMediaQuery("(max-width:1536px)");
  const id = user?.sub.substring(user?.sub.indexOf("|") + 1);
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });

  const { data: project } = useGetProjectInfoQuery({
    subClaim: user?.sub,
    projectId: projectId,
  });

  const { data: report } = useGetProjectReportQuery({
    subClaim: user?.sub,
    projectId: projectId,
  });

  const { _id, firstName, lastName } = userInfo?.user ?? {};

  var formattedString = uploadedAt
    .replace("T", "")
    .replace("Z", "")
    .replace(/-/g, ".");
  var uploadedAtString = formattedString.slice(0, 10);

  let index = 1;

  return (
    <>
      {tasks
        .filter((task) => {
          if (input === "") {
            //if query is empty
            return task;
          } else if (task.name.toLowerCase().includes(input.toLowerCase())) {
            //returns filtered array
            return task;
          }
        })
        .map((task) => {
          return isCaseViewable(projectType, task?.slides.length) ? (
            <>
              <Link
                style={{ textDecoration: "none" }}
                w="100%"
                _focus={{ border: "none" }}
                as={RouteLink}
                to={{
                  pathname: `/${id}/project/${projectId}/slideRedirect`,
                  state: {
                    caseId: task._id,
                    questionnaire: questionnaire,
                    userIdToQuery: userInfo?.user._id,
                  },
                }}
              >
                {projectType === "multiSlide" ? (
                  <HStack
                    w="100%"
                    fontFamily="inter"
                    borderBottom="1px solid #000"
                    color="#151C25"
                    minH="6.9444vh"
                    fontWeight={600}
                    _hover={{
                      bg: isCaseViewable(projectType, task?.slides.length)
                        ? "#F8F8F5"
                        : "red.300",
                    }}
                    bgColor={
                      isCaseViewable(projectType, task?.slides.length)
                        ? "none"
                        : "red.100"
                    }
                  >
                    <Text
                      minW="8%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      textAlign="center"
                    >
                      {index++}
                    </Text>
                    <Image src={newCasesIcon} width="1vw" height="1.8vh" />
                    <Text
                      minW="16.7%"
                      fontFamily="fira sans"
                      fontWeight="600"
                      fontSize="0.9375vw"
                      display="flex"
                    >
                      {task.name}
                    </Text>

                    <Text
                      minW="12%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {task.slides.length < 2 ? "NA" : task.slides[0].slideName}
                    </Text>

                    <Text
                      minW="10%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {slideType + " Stain"}
                    </Text>
                    <Text
                      minW="12%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {task.slides.length < 2 ? "NA" : task.slides[1].slideName}
                    </Text>
                    <Text
                      minW="10%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {slideType + " Stain"}
                    </Text>
                    <Text minW="13.8%" fontSize="0.7291vw" textTransform="none">
                      {uploadedAtString}
                    </Text>

                    <Text
                      minW="13%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {projectInfo?.casesProgress?.find(
                        (o) =>
                          o.case === task._id && o.caseStatus === "completed"
                      ) ? (
                        <HStack>
                          <Image
                            src={completedProjectIcon}
                            width="1vw"
                            height="1.5vh"
                          />
                          <Text
                            fontSize="0.7291vw"
                            fontFamily="inter"
                            textTransform="none"
                            cursor="pointer"
                          >
                            {"View Report   "}
                            <CaseReport
                              report={report}
                              reportType={projectType}
                              caseId={task?._id}
                              userId={{ _id, firstName, lastName }}
                            />
                          </Text>
                        </HStack>
                      ) : (
                        <HStack>
                          <Image
                            src={nonCompletedProjectIcon}
                            width="1vw"
                            height="1.5vh"
                          />
                          <Text
                            fontSize="0.7291vw"
                            fontFamily="inter"
                            textTransform="none"
                          >
                            Pending
                          </Text>
                        </HStack>
                      )}
                    </Text>
                  </HStack>
                ) : (
                  <HStack
                    w="100%"
                    fontFamily="inter"
                    borderBottom="1px solid #000"
                    _hover={{ background: "#F8F8F5", transition: "0.4s" }}
                    color="#151C25"
                    minH="6.9444vh"
                    fontWeight={600}
                  >
                    <Text
                      minW="8%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                      textAlign="center"
                    >
                      {index++}
                    </Text>
                    <Image src={newCasesIcon} width="1vw" height="1.8vh" />
                    <Text
                      minW="28.2%"
                      fontFamily="fira sans"
                      fontWeight="600"
                      fontSize="0.9375vw"
                      display="flex"
                    >
                      {task.name}
                    </Text>

                    <Text
                      minW="25%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {slideType + " Stain"}
                    </Text>
                    <Text minW="20%" fontSize="0.7291vw" textTransform="none">
                      {uploadedAtString}
                    </Text>

                    <Text
                      minW="13%"
                      fontSize="0.7291vw"
                      fontFamily="inter"
                      textTransform="none"
                    >
                      {projectInfo?.casesProgress?.find(
                        (o) =>
                          o.case === task._id && o.caseStatus === "completed"
                      ) ? (
                        <HStack>
                          <Image
                            src={completedProjectIcon}
                            width="1vw"
                            height="1.5vh"
                          />
                          <Text
                            fontSize="0.7291vw"
                            fontFamily="inter"
                            textTransform="none"
                            cursor="pointer"
                          >
                            {"View Report   "}
                            <CaseReport
                              report={report}
                              reportType={projectType}
                              caseId={task?._id}
                              userId={{ _id, firstName, lastName }}
                            />
                          </Text>
                        </HStack>
                      ) : (
                        <HStack>
                          <Image
                            src={nonCompletedProjectIcon}
                            width="1vw"
                            height="1.5vh"
                          />
                          <Text
                            fontSize="0.7291vw"
                            fontFamily="inter"
                            textTransform="none"
                            cursor="default"
                          >
                            Pending
                          </Text>
                        </HStack>
                      )}
                    </Text>
                  </HStack>
                )}
              </Link>
            </>
          ) : (
            <>
              {projectType === "multiSlide" ? (
                <HStack
                  w="100%"
                  fontFamily="inter"
                  borderBottom="1px solid #000"
                  color="#151C25"
                  minH="6.9444vh"
                  fontWeight={600}
                  _hover={{
                    bg: isCaseViewable(projectType, task?.slides.length)
                      ? "#F8F8F5"
                      : "red.300",
                  }}
                  bgColor={
                    isCaseViewable(projectType, task?.slides.length)
                      ? "none"
                      : "red.100"
                  }
                >
                  <Text
                    minW="8%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                    textAlign="center"
                  >
                    {index++}
                  </Text>
                  <Image src={newCasesIcon} width="1vw" height="1.8vh" />
                  <Text
                    minW="16.7%"
                    fontFamily="fira sans"
                    fontWeight="600"
                    fontSize="0.9375vw"
                    display="flex"
                  >
                    {task.name}
                  </Text>

                  <Text
                    minW="12%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {task.slides.length < 2 ? "NA" : task.slides[0].slideName}
                  </Text>

                  <Text
                    minW="10%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {slideType + " Stain"}
                  </Text>
                  <Text
                    minW="12%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {task.slides.length < 2 ? "NA" : task.slides[1].slideName}
                  </Text>
                  <Text
                    minW="10%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {slideType + " Stain"}
                  </Text>
                  <Text minW="13.8%" fontSize="0.7291vw" textTransform="none">
                    {uploadedAtString}
                  </Text>

                  <Text
                    minW="13%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {projectInfo?.find((o) => o._id === task._id) ? (
                      <HStack>
                        <Image
                          src={completedProjectIcon}
                          width="1vw"
                          height="1.5vh"
                        />
                        <Text
                          fontSize="0.7291vw"
                          fontFamily="inter"
                          textTransform="none"
                          cursor="pointer"
                          onClick={(event) => event.stopPropagation()}
                        >
                          {"View Report   "}
                          <CaseReport
                            report={report}
                            reportType={projectType}
                            caseId={task?._id}
                            userId={{ _id, firstName, lastName }}
                          />
                        </Text>
                      </HStack>
                    ) : (
                      <HStack>
                        <Image
                          src={nonCompletedProjectIcon}
                          width="1vw"
                          height="1.5vh"
                        />
                        <Text
                          fontSize="0.7291vw"
                          fontFamily="inter"
                          textTransform="none"
                        >
                          Pending
                        </Text>
                      </HStack>
                    )}
                  </Text>
                </HStack>
              ) : (
                <HStack
                  w="100%"
                  fontFamily="inter"
                  borderBottom="1px solid #000"
                  _hover={{ background: "#F8F8F5", transition: "0.4s" }}
                  color="#151C25"
                  minH="6.9444vh"
                  fontWeight={600}
                >
                  <Text
                    minW="8%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                    textAlign="center"
                  >
                    {index++}
                  </Text>
                  <Image src={newCasesIcon} width="1vw" height="1.8vh" />
                  <Text
                    minW="28.2%"
                    fontFamily="fira sans"
                    fontWeight="600"
                    fontSize="0.9375vw"
                    display="flex"
                  >
                    {task.name}
                  </Text>

                  <Text
                    minW="25%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {slideType + " Stain"}
                  </Text>
                  <Text minW="20%" fontSize="0.7291vw" textTransform="none">
                    {uploadedAtString}
                  </Text>

                  <Text
                    minW="13%"
                    fontSize="0.7291vw"
                    fontFamily="inter"
                    textTransform="none"
                  >
                    {projectInfo?.find((o) => o._id === task._id) ? (
                      <HStack>
                        <Image
                          src={completedProjectIcon}
                          width="1vw"
                          height="1.5vh"
                        />
                        <Text
                          fontSize="0.7291vw"
                          fontFamily="inter"
                          textTransform="none"
                          cursor="pointer"
                          onClick={(event) => event.stopPropagation()}
                        >
                          {"View Report   "}
                          <CaseReport
                            report={report}
                            reportType={projectType}
                            caseId={task?._id}
                            userId={{ _id, firstName, lastName }}
                          />
                        </Text>
                      </HStack>
                    ) : (
                      <HStack>
                        <Image
                          src={nonCompletedProjectIcon}
                          width="1vw"
                          height="1.5vh"
                        />
                        <Text
                          fontSize="0.7291vw"
                          fontFamily="inter"
                          textTransform="none"
                          cursor="default"
                        >
                          Pending
                        </Text>
                      </HStack>
                    )}
                  </Text>
                </HStack>
              )}
            </>
          );
        })}
    </>
  );
};

export default DisplayCases;
