import React, { useState } from "react";
import { Divider, Text, Button } from "@chakra-ui/react";
import AnswersPreview from "./answersPreviewUpdated";
import { useLocation } from "react-router-dom";
import Questionnaire from "../Qna/questionnaire";
import { Scrollbars } from "react-custom-scrollbars";
import "../../styles/scrollBar.css";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetQuestionnaireResponseQuery,
  useGetProjectInfoQuery,
} from "../../state/api/medicalApi";
import _ from "lodash";
import MultiViewerQuestions from "./multiviewerQuestions";

const Questions = () => {
  const [question, setQuestion] = useState("first-slide");
  const [firstSlideQna, setFirstSlideQna] = useState({
    qna: {},
    response: {},
  });
  const [secondSlideQna, setSecondSlideQna] = useState({
    qna: {},
    response: {},
  });
  const location = useLocation();
  const { user } = useAuth0();

  const { data: response, isLoading } = useGetQuestionnaireResponseQuery({
    subClaim: user?.sub,
    questionnaireId: location?.state.questionnaire?._id,
    slideId: location?.state.viewerIds[0]?._id,
    userIdToQuery: location?.state.userIdToQuery,
  });

  const { data: project } = useGetProjectInfoQuery({
    subClaim: user?.sub,
    projectId: location.state.projectId,
  });

  return (
    <>
      <>
        {project?.type === "multiSlide" ? (
          <>
            <Button
              onClick={() => {
                setQuestion("first-slide");
              }}
              width="50%"
              borderRadius="none"
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "none" }}
              bgColor={question === "first-slide" ? "#E4E5E8" : "#F8F8F5"}
              borderBottom="1px solid #000"
              h="32px"
              borderLeft="1px solid #000"
              borderRight="1px solid #000"
              fontFamily="inter"
              fontSize="14px"
              fontWeight="400"
            >
              Left-Slide
            </Button>
            <Button
              onClick={() => {
                setQuestion("second-slide");
              }}
              width="50%"
              borderRadius="none"
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "none" }}
              bgColor={question === "second-slide" ? "#E4E5E8" : "#F8F8F5"}
              borderBottom="1px solid #000"
              h="32px"
              borderRight="1px solid #000"
              fontFamily="inter"
              fontSize="14px"
              fontWeight="400"
            >
              Right-Slide
            </Button>
          </>
        ) : (
          ""
        )}
        {/*  */}
        {question === "first-slide" ? (
          <>
            <Scrollbars
              style={{ width: "100%", height: "79vh" }}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical-messageBox" />
              )}
            >
              <Text mb={3} fontSize="lg">
                {location.state?.slideType}
              </Text>
              <Divider />
              {isLoading ? (
                <Text>...</Text>
              ) : (
                <>
                  <Questionnaire
                    direction="column"
                    questions={location?.state.questionnaire?.questions}
                    response={response}
                    slideQna={firstSlideQna}
                    setSlideQna={setFirstSlideQna}
                  />
                </>
              )}
            </Scrollbars>
          </>
        ) : (
          <MultiViewerQuestions
            slideQna={secondSlideQna}
            setSlideQna={setSecondSlideQna}
          />
        )}
      </>
      {_.isEmpty(response) && (
        <AnswersPreview
          questionnaire={location?.state.questionnaire}
          SlideId={location?.state.viewerIds}
          firstSlideQna={firstSlideQna}
          secondSlideQna={secondSlideQna}
        />
      )}
    </>
  );
};

export default Questions;
