import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMembers } from "../../state/reducers/newProjectReducer";
import "../../styles/newproject.css";
import {
  Box,
  Text,
  Input,
  InputGroup,
  Menu,
  InputRightElement,
  MenuList,
  MenuItem,
  MenuButton,
  Image,
  Icon,
  Avatar,
  HStack,
  VStack,
  Divider,
  Spacer,
} from "@chakra-ui/react";

import { AddIcon, ChevronDownIcon, LinkIcon } from "@chakra-ui/icons";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import {
  useGetUserInfoQuery,
  useSearchUserMutation,
} from "../../state/api/medicalApi";
import { useAuth0 } from "@auth0/auth0-react";

const Share = () => {
  const [userImage, setUserImage] = useState();
  const { user } = useAuth0();
  const { data } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const [value, setValue] = useState("");
  const [isInvalidUser, setIsInvalidUser] = useState("");
  const { membersInfo } = useSelector((state) => state.newProjectState);
  const [searchUser] = useSearchUserMutation();
  const dispatch = useDispatch();

  const handleInput = (e) => {
    setValue(e.target.value);
    if (isInvalidUser) setIsInvalidUser("");
  };
  const handleAddMember = async () => {
    if (!value) {
      setIsInvalidUser("Empty field: email required");
      setValue("");
      return;
    }
    if (value === data?.user.emailAddress) {
      setIsInvalidUser("User already added");
      setValue("");
      return;
    }
    const info = await searchUser({
      subClaim: user?.sub,
      searchObject: { emailAddress: value },
    });
    if (info?.data?._id) {
      if (info?.data?.has_one_project)
        setIsInvalidUser("User involved in another study");
      else if (membersInfo.some((user) => user.email === value))
        setIsInvalidUser("User already added");
      else dispatch(addMembers({ email: value, info: info.data }));
      setValue("");
    } else setIsInvalidUser("Not a valid user");
  };

  return (
    <>
      <Box>
        <Box className="questions_div" fontFamily="inter">
          <Box marginTop={-16} fontSize={14} color="#000" marginLeft="28px">
            Share with readers
            <br />
            <InputGroup>
              <Input
                width={730}
                name="readers_list"
                marginTop="18px"
                border="1px"
                borderColor="#fff"
                opacity={0.6}
                bg="white"
                value={value}
                type="email"
                borderRadius={0}
                placeholder="Reader's email address"
                onChange={(e) => handleInput(e)}
              />
              <InputRightElement
                marginTop="18px"
                onClick={handleAddMember}
                cursor="pointer"
                children={
                  <AiOutlineUserAdd name="add" color="#00153F" size={20} />
                }
              />
            </InputGroup>
            {isInvalidUser && (
              <Text mt={2} ml={2} fontSize={16} color="red">
                {isInvalidUser}
              </Text>
            )}
          </Box>
          <br />
          <Box marginTop={50} marginLeft={-735}></Box>
          <Box
            className="questions_div"
            w="100%"
            display="block"
            marginTop="40px"
            marginLeft="00px"
          >
            <HStack alignItems="center">
              <Avatar
                size="md"
                borderRadius="full"
                name={`${data?.user.firstName} ${data?.user.lastName}`}
                // src={userImage}
                alt="User"
                mt={3}
              />
              <VStack ml={2} align="flex-start" spacing={0} whiteSpace="nowrap">
                <Text
                  fontSize={16}
                  color="#000"
                  pt={2}
                  fontWeight={600}
                  fontFamily="fira sans"
                >
                  {`Dr. ${data?.user.firstName} ${data?.user.lastName} (You)`}
                </Text>
                <Text fontSize={12} color="#000" fontFamily="inter">
                  {data?.user.emailAddress}
                </Text>
              </VStack>
              {/* <Spacer w="400px" /> */}
              <Text
                // fontStyle="italic"
                color="#000"
                fontSize={14}
                fontFamily="fira sans"
                fontWeight={600}
                pos="absolute"
                right="28px"
              >
                Owner
              </Text>
            </HStack>
            <Divider mt={4} border="none" h="0.1px" w={550} />
            {membersInfo.map((member) => {
              return (
                <HStack key={member._id}>
                  <Avatar
                    size="md"
                    borderRadius="full"
                    name={`${member.firstName} ${member.lastName}`}
                    // src={userImage}
                    alt="User"
                    mt={3}
                  />
                  <VStack
                    ml={2}
                    align="flex-start"
                    spacing={0}
                    whiteSpace="nowrap"
                  >
                    <Text
                      fontSize={16}
                      color="#000"
                      pt={2}
                      fontWeight={600}
                      fontFamily="fira sans"
                    >
                      {`Dr. ${member.firstName} ${member.lastName}`}
                    </Text>
                    <Text fontSize={12} color="#000" fontFamily="inter">
                      {member.email}
                    </Text>
                  </VStack>
                  <Spacer />
                  <Text
                    // fontStyle="italic"
                    color="#000"
                    fontSize={14}
                    fontFamily="fira sans"
                    fontWeight={600}
                    pos="absolute"
                    right="28px"
                  >
                    Reader
                  </Text>
                </HStack>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Share;
