export const widths = [
  {
    pixelWidth: 2,
    size: "Sm",
    boxSize: 2,
  },
  {
    pixelWidth: 4,
    size: "Md",
    boxSize: 4,
  },
  { pixelWidth: 6, size: "Lg", boxSize: 6 },
];
