import { useAuth0 } from "@auth0/auth0-react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import {
  useGetProjectReportQuery,
  useGetUserInfoQuery,
} from "../../state/api/medicalApi";
import ReportDocument from "./reportDocument";
import { Button, Image } from "@chakra-ui/react";
import { getUserId } from "../../hooks/utility";
import downloadReportIcon from "../../images/new-project-images/downloadReportIcon.svg";

const ProjectReport = ({ projectId, reportType }) => {
  const { user } = useAuth0();
  const { isLoading, data: report } = useGetProjectReportQuery({
    subClaim: user?.sub,
    projectId: projectId,
  });
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });

  return (
    <PDFDownloadLink
      document={<ReportDocument report={report} reportType={reportType} />}
      fileName="projectReport.pdf"
    >
      <Button
        fontFamily="fira sans"
        fontSize="14px"
        fontWeight="600"
        colorScheme="#ececec"
        color="#000"
        textDecoration="underline"
        title="download report"
        right={userInfo?.user.userType === "reader" ? "40%" : "2%"}
        disabled={isLoading}
        onClick={(event) => event.stopPropagation()}
      >
        {/* {userInfo?.user.userType === "reader" ? (
          <Image src={downloadReportIcon}></Image>
        ) : (
          "Project Report"
        )} */}
        <Image src={downloadReportIcon}></Image>
      </Button>
    </PDFDownloadLink>
  );
};

export default ProjectReport;
